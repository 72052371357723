import { mapState } from 'vuex';

export default {
	config: mapState([
		'filter', // Used to track if the user is creating a new filter
		'filterApprove', // Used to track if the user is approving a filter
		'filterDelete', // Used to track if the user is deleting a filter
		'filterLoadingNew', // Used to track if the user is creating a new filter
		'filterNew', // Used to track if the user is creating a new filter
		'filterNewInputValue', // Used to track if the user is creating a new filter
		'filterNotification', // Used to hide the filter notification
		'filters', // User's filters retrieved from GCP after Oauth
		'label', // Used to track if the user is creating a new filter
		'labelApprove', // Used to track if the user is approving a filter
		'labelDelete', // Used to track if the user is deleting a filter
		'labelIdArray', // Array of label IDs for typeahead
		'labelNameArray', // Array of labels names for typeahead
		'labelNew', // Used to track if the user is creating a new filter
		'labelPathArray', // Array of label paths for typeahead
		'labels', // User's labels retrieved from GCP after Oauth
	]),
};
