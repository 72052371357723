<template>
	<div class="google-login-button-container">
		<div class="social-auth">
			<a :href="`${apiUrl}`" class="google-auth">
				<img src="../assets/google.png" alt="Google Logo" />
				<span>Google</span>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GoogleLoginButton',
	data() {
		return {
			oldRouteHash: '',
			apiUrl: process.env.VUE_APP_API_URL,
		};
	},
	created() {
		console.log('VUE_APP_API_URL', process.env.VUE_APP_API_URL);
	},
	computed: {},
	beforeRouteEnter() {
		this.oldRouteHash = location.hash; /** if hash after Oauth request */
	},
};
</script>

<style>
.google-login-button-container {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.social-auth {
	max-width: 27rem;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.social-auth:hover {
	animation: grow 100ms;
	animation-timing-function: ease-in-out;
	transform: scale(1.5);
}
@keyframes grow {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.5);
	}
}
.google-auth {
	background-color: #fff;
	border-radius: 5px;
	padding: 0.6rem 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-in-out;
	cursor: pointer !important;
}
.google-auth:hover {
	box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
}
.google-auth img {
	height: 4rem;
	margin-right: 1rem;
	cursor: pointer !important;
}
.google-auth span {
	font-size: 1.8rem;
	cursor: pointer !important;
}
</style>
