import { mapState } from 'vuex';

export default {
	config: mapState([
		// APP
		'error', // Toggle error
		'errorMessage', // Initialized with empty string
		'filterNew', // Used to track if the user is creating a new filter
		'labelNew', // Used to track if the user is creating a new label
	]),
};
