import store from '../store/store';
import * as actions from '../store/actions/actions';
export const LocationMethods = {
	methods: {
		hashCleaner() {
			console.debug('HASH CLEANER');
			let scrollV,
				scrollH,
				location = window.location,
				token = location.hash;
			if (token) {
				setTimeout(() => {
					window.location.hash = '';
				}, 0);
				store.dispatch(actions.SET_OAUTH_ACCESS_TOKEN, token);
			}
			if ('pushState' in history) history.pushState(null, document.title, location.pathname + location.search);
			else {
				// Prevent scrolling by storing the page's current scroll offset
				scrollV = document.body.scrollTop;
				scrollH = document.body.scrollLeft;
				// Restore the scroll offset, should be flicker free
				document.body.scrollTop = scrollV;
				document.body.scrollLeft = scrollH;
			}
		},
		getHash() {
			/** Returns the window location hash or false when empty hash */
			return store.windowLocationHash !== '' && store.windowLocationHash.length > 0
				? store.windowLocationHash
				: false;
		},
	},
};
