import axios from 'axios';
export default class ApiService {
	constructor(baseURL) {
		this.axios = axios.create({
			baseURL: baseURL,
		});
		// this.requestHeaders = {
		// 	'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
		// 	'Access-Control-Allow-Headers':
		// 		'Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Requested-With, Access-Control-Allow-Origin',
		// 	'Access-Control-Allow-Origin': '*',
		// 	'Content-Type': 'application/json',
		// };
	}
	async filterCreate({ endpoint, token, data }) {
		console.debug('filterCreate()');
		return await this.axios.post(endpoint, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		//TODO: We need to account for data & data structure validation from GCP request responses
		//TODO: Account for bad credential token due to expiration based on response from GCP
	}
	async filterDelete({ endpoint, token, id }) {
		console.debug('filterDelete()');
		return await this.axios.delete(`${endpoint}/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}
	async labelCreate({ endpoint, token, data }) {
		console.debug('labelCreate()');
		return await this.axios.post(endpoint, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		//TODO: We need to account for data & data structure validation from GCP request responses
		//TODO: Account for bad credential token due to expiration based on response from GCP
	}
	async labelDelete({ endpoint, token, id }) {
		console.debug('labelDelete()');
		return await this.axios.delete(`${endpoint}/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}
	async labelUpdate({ endpoint, token, data }) {
		console.debug('labelUpdate()');
		console.log('data: ', data);
		const requestBody = {
			labelListVisibility: data.labelListVisibility,
			messageListVisibility: data.messageListVisibility,
			name: data.name,
		};
		return await this.axios.put(`${endpoint}/${data.id}`, requestBody, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		//TODO: We need to account for data & data structure validation from GCP request responses
		//TODO: Account for bad credential token due to expiration based on response from GCP
	}
}
