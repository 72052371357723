// export const GET_ACCEPTANCE = 'get:acceptance';
// export const GET_ACTIVE_FILTER = 'get:active:filter';
// export const GET_ACTIVE_LABEL = 'get:active:label';
// export const GET_ACTIVE_TOOL = 'get:active:tool';
// export const GET_APP_STAGE = 'get:app:stage';
// export const GET_APP_STATE = 'get:app:state';
// export const GET_AUTH_TOKEN = 'get:auth:token';
// export const GET_DEV_MODE = 'get:dev:mode';
// export const GET_FILTERS = 'get:filters';
// export const GET_FILTERS_ALL_ENDPOINT = 'get:filters:all:endpoint';
// export const GET_FILTERS_LOADED = 'get:filters:loaded';
// export const GET_FILTERS_PREVIOUS_VIEW = 'get:filter:previous:view';
// export const GET_HEADER_MESSAGE = 'get:header:message';
// export const GET_INDEXED_ARRAY = 'get:indexed:array';
// export const GET_LABEL_EXTRA_CRISPY = 'get:label:extra:crispy';
// export const GET_LABEL_ID_ARRAY = 'get:label:id:array';
// export const GET_LABEL_NAME_ARRAY = 'get:label:name:array';
// export const GET_LABEL_PATH_ARRAY = 'get:label:path:array';
// export const GET_LABEL_ORIGINAL = 'get:label:original';
// export const GET_LABELS = 'get:labels';
// export const GET_LABELS_ALL_ENDPOINT = 'get:labels:all:endpoint';
// export const GET_LABELS_LOADED = 'get:labels:loaded';
// export const GET_LABELS_PREVIOUS_VIEW = 'get:labels:previous:view';
// export const GET_LOADING_MESSAGE = 'get:loading:message';
export const GET_LOCAL_STORAGE = 'get:local:storage';
// export const GET_MESSAGE = 'get:message';
// export const GET_MESSAGE_TRACKER = 'get:message';
// export const GET_NEW_LABEL = 'get:new:label';
// export const GET_NEW_FILTER = 'get:new:filter';
// export const GET_OAUTH_TOKEN = 'get:oauth:token';
// export const GET_USER_DATA_LOADED = 'get:user:data:loaded';
// export const GET_USER_EMAIL = 'get:user:email';
// export const GET_USER_EMAIL_LOADED = 'get:user:email:loaded';
// export const GET_USER_INFO_ENDPOINT = 'get:user:info:endpoint';
// export const GET_SUGGESTIONS = 'get:suggestions';
// export const GET_TOOL_VIEW = 'get:tool:view';
// export const GET_TOOL_VIEW_LABEL = 'get:tool:view:label';
// export const GET_TOOL_VIEW_FILTER = 'get:tool:view:filter';
// export const GET_TYPEAHEAD_SUGGESTIONS = 'get:typeahead:suggestions';
export const GET_VALID_TOKEN = 'get:valid:token';
export const GET_VALID_TOKEN_LOCAL = 'get:valid:token:local';
// export const GET_WINDOW_LOCATION_HASH = 'get:window:location:hash';
export const GET_WINDOW_LOCATION_HASH_EXISTS = 'get:window:location:hash:exists';
