<template>
	<div class="loading-indicator">
		<span class="loader__element"></span>
		<span class="loader__element"></span>
		<span class="loader__element"></span>
		<span class="loader__element"></span>
	</div>
	<div class="loading-message">
		<slot>
			<span class="loader__message__element">{{ message }}</span>
		</slot>
	</div>
</template>

<script>
export default {
	name: 'LoadingIndicator',
};
</script>

<style>
:root {
	/* Google Brand Colors */
	--google-blue: #4285f4;
	--google-green: #34a853;
	--google-yellow: #fbbc05;
	--google-red: #ea4335;
	--size: 5px;
}
.loading-indicator {
	display: flex;
	overflow: hidden;
	position: absolute;
	align-items: center;
	align-content: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100000;
}
.loader__element {
	border-radius: 100%;
	margin: calc(var(--size) * 2);
}
.loader__element:nth-child(1) {
	border: var(--size) solid #ffffff;
	animation: preloader 0.6s ease-in-out alternate infinite;
}
.loader__element:nth-child(2) {
	border: var(--size) solid var(--google-green);
	animation: preloader 0.6s ease-in-out alternate 0.2s infinite;
}
.loader__element:nth-child(3) {
	border: var(--size) solid var(--google-yellow);
	animation: preloader 0.6s ease-in-out alternate 0.4s infinite;
}
.loader__element:nth-child(4) {
	border: var(--size) solid var(--google-red);
	animation: preloader 0.6s ease-in-out alternate 0.6s infinite;
}
@keyframes preloader {
	100% {
		transform: scale(2);
	}
}
.loading-message {
	display: flex;
	overflow: hidden;
	position: absolute;
	align-items: center;
	align-content: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100000;
	padding-top: 100px;
}
.loader__message__element {
	margin: calc(var(--size) * 2);
}
</style>
