export const StageLogic = {
	methods: {
		async switch(stage, state, commit, actions) {
			switch (stage) {
				case 0:
					if (state.GET_VALID_TOKEN && state.userDataLoaded) {
						console.debug('appStage: 0, validToken, userDataLoaded');
						commit('SET_APP_STATE', state.GET_LOCAL_STORAGE);
						await debounce(() => {
							commit('SET_APP_STAGE', 1);
						}, 200);
					} else if (!state.GET_LOCAL_STORAGE && !state.GET_VALID_TOKEN && !state.userDataLoaded) {
						console.debug('appStage: 0, no localStorage, no userData, no token');
						console.debug('Updating stage: 1');
						actions.SET_HEADER_MESSAGE();
						commit('SET_APP_STAGE', 1);
					}
					break;
				case 1:
					console.debug('Current stage: 1');
					actions.SET_HEADER_MESSAGE();
					break;
				case 2:
					console.debug('Hash found, updating stage: 2');
					actions.locationCleaner();
					commit('SET_APP_STAGE', 2);
					break;
				case 3:
					if (state.GET_VALID_TOKEN && !state.userDataLoaded) {
						console.debug('Updating from stage: 2');
						await state.getUserData();
						if (state.userDataLoaded) {
							commit('SET_APP_STAGE', 3);
							actions.SET_LOCAL_STORAGE();
							state.setUserDataLoaded(true);
						} else {
							// Handle the case when userDataLoaded is still false
						}
					}
					break;
				case 4:
					if (state.acceptance) {
						console.debug('Current Stage: 4');
						actions.SET_LOCAL_STORAGE();
					}
					break;
				default:
					break;
			}
		},
	},
};
