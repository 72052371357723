<template>
	<div id="app" ref="app">
		<!-- HEADER -->
		<!-- <page-fade-animation fade="in"> -->
		<header id="page-header">
			<h1>gFilta</h1>
			<div id="site-header-logo-container">
				<!-- SVG LOGO -->
				<svg
					version="1.1"
					id="Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 128 55"
					style="enable-background: new 0 0 128 55"
					xml:space="preserve">
					<linearGradient
						id="SVGID_1_"
						gradientUnits="userSpaceOnUse"
						x1="64.4031"
						y1="42.5962"
						x2="64.4031"
						y2="54.3043">
						<stop offset="0" style="stop-color: #fbbc05" />
						<stop offset="1" style="stop-color: #d6a204" />
					</linearGradient>
					<path
						class="st0"
						d="M73,42.6l-3.8,3.9v6.7c0,0.6-0.5,1.1-1.1,1.1h-7.4c-0.6,0-1.1-0.5-1.1-1.1l0-6.7l-3.8-3.9L73,42.6z" />
					<g>
						<linearGradient
							id="SVGID_00000011742620875986152830000007625837969325749660_"
							gradientUnits="userSpaceOnUse"
							x1="64.3984"
							y1="29.9467"
							x2="64.3984"
							y2="40.4532">
							<stop offset="0" style="stop-color: #ea4335" />
							<stop offset="1" style="stop-color: #c43a2d" />
						</linearGradient>
						<polygon
							style="fill: url(#SVGID_00000011742620875986152830000007625837969325749660_)"
							points="43.5,29.9 53.7,40.5 75.1,40.5 
          85.3,29.9  " />
					</g>
					<g>
						<linearGradient
							id="SVGID_00000168081862780766707220000003883098766642496140_"
							gradientUnits="userSpaceOnUse"
							x1="64.393"
							y1="15.6584"
							x2="64.393"
							y2="27.4292">
							<stop offset="0" style="stop-color: #34a853" />
							<stop offset="1" style="stop-color: #2b8c45" />
						</linearGradient>
						<polygon
							style="fill: url(#SVGID_00000168081862780766707220000003883098766642496140_)"
							points="87.8,27.4 99.3,15.7 29.5,15.7 
          41,27.4  " />
					</g>
					<linearGradient
						id="SVGID_00000096024263633030784350000011982819431857589691_"
						gradientUnits="userSpaceOnUse"
						x1="64"
						y1="0.554"
						x2="64"
						y2="13.2317">
						<stop offset="0" style="stop-color: #4285f4" />
						<stop offset="1" style="stop-color: #3872cf" />
					</linearGradient>
					<path
						style="fill: url(#SVGID_00000096024263633030784350000011982819431857589691_)"
						d="M101.6,13.2l10.4-10.7h4.2c0.5,0,1-0.4,1-1s-0.4-1-1-1H11.8c-0.5,0-1,0.4-1,1s0.4,1,1,1h4.9l10.5,10.7H101.6z" />
				</svg>
			</div>
			<h2>{{ headerMessage }}</h2>
		</header>
		<!-- </page-fade-animation> -->
		<!-- MAIN -->
		<main id="main">
			<!-- LANDING 0 -->
			<router-view v-if="appStage === 0" name="Index">
				<!-- <page-fade-animation v-if="verifyLoadingStep(1)" fade="in"> -->
				<slider-panel-animation :animate-config="{ x: 'top', y: 'center' }" background="blue" id="slider-00">
					<LandingPage @stage="setAppStage(1), setHeaderMessage()"></LandingPage>
				</slider-panel-animation>
				<!-- </page-fade-animation> -->
			</router-view>
			<!-- LOGIN 1 -->
			<router-view v-if="appStage === 1 && !oauthAccessToken" name="Login">
				<!-- <page-fade-animation v-if="verifyLoadingStep(1)" fade="in"> -->
				<slider-panel-animation :animate-config="{ x: 'top', y: 'center' }" background="blue" id="slider-01">
					<google-login-button @token="setOauthAccessToken" />
				</slider-panel-animation>
				<!-- </page-fade-animation> -->
			</router-view>
			<!-- LOADING 2 -->
			<router-view v-if="appStage === 2 && oauthAccessToken" name="Loading">
				<!-- <page-fade-animation fade="in"> -->
				<slider-panel-animation :animate-config="{ x: 'top', y: 'center' }" background="blue" id="slider-02">
					<loading-indicator>{{ loadingMessage }}</loading-indicator>
				</slider-panel-animation>
			</router-view>
			<!-- </page-fade-animation> -->
			<!-- ACCEPTANCE 3 -->
			<router-view v-if="appStage === 3 && userDataLoaded && oauthAccessToken" name="Acceptance">
				<!-- <page-fade-animation v-if="verifyLoadingStep(3)" fade="in"> -->
				<slider-panel-animation :animate-config="{ x: 'top', y: 'center' }" background="green" id="slider-03">
					<div id="message-all-set">
						<div class="message-all-set-section-1"><h3>Thanks for choosing gFilta!</h3></div>
						<div class="message-all-set-section-2">
							<h4>We're thrilled to have you on board.<br />Your Filters and Labels are Ready</h4>
						</div>
						<div class="message-all-set-section-3">
							Effortlessly organize your Gmail filters and labels for a streamlined email experience,<br />
							saving you valuable time in your workflow.
						</div>
						<div class="message-all-set-section-4"><h5>Data Privacy and Compliance</h5></div>
						<div class="message-all-set-section-5">
							<ul>
								<li>
									Your data is handled securely: gFilta operates as a 1st party data application,
									ensuring compliance with data protection regulations.
								</li>
								<li>
									We are fully committed to your privacy: We adhere to strict data protection
									practices and comply with relevant laws and regulations to keep your information
									safe.
								</li>
								<li>
									Pricing that fits your needs: gFilta offers competitive pricing plans designed to
									meet the requirements of American businesses.
								</li>
							</ul>
						</div>
						<div class="message-all-set-section-6">
							<h5>Important Notice for Non-United States Residents</h5>
						</div>
						<div class="message-all-set-section-7">
							<p>
								By checking the box below, you confirm that you are currently a resident of the United
								States.<br />
								Please note that while gFilta strives to maintain compliance with applicable
								regulations,<br />
								we cannot guarantee compliance if you choose to continue using the application.<br />
							</p>
						</div>
						<div class="message-all-set-section-8">
							<div class="acceptance-checkbox">
								<input
									@click="this.usResidentCheckbox = !this.usResidentCheckbox ? true : false"
									type="checkbox"
									id="us-residence-checkbox"
									required />
								<label for="us-residence-checkbox"
									>I confirm that I am a resident of the United States and understand the compliance
									disclaimer.</label
								>
							</div>
							<!-- <div @click="document.getElementById('us-residence-checkbox').value ? setUserAcceptance() : false" id="start-organizing-button">Get Started</div> -->
							<div
								@click="this.usResidentCheckbox ? setUserAcceptance() : false"
								id="start-organizing-button"
								class="landing-page-content-button">
								<button class="landing-button">Get Started</button>
							</div>
						</div>
					</div>
				</slider-panel-animation>
				<!-- </page-fade-animation> -->
			</router-view>
			<!-- TOOLS 4 -->
			<router-view v-if="appStage === 4 && userDataLoaded && oauthAccessToken" name="Tools">
				<!-- <page-fade-animation v-if="verifyLoadingStep(4)" fade="in"> -->
				<slider-panel-animation :animate-config="{ x: 'top', y: 'center' }" background="blue" id="slider-04">
					<tool-wrapper @set-active-label="this.setActiveLabel" tool-type="label"> </tool-wrapper>
					<tool-wrapper @set-active-filter="this.setActiveFilter" tool-type="filter"> </tool-wrapper>
				</slider-panel-animation>
				<!-- </page-fade-animation> -->
			</router-view>
		</main>
	</div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import * as actions from './store/actions/actions';
import * as getters from './store/getters/getters';
import appConfig from '@/store/maps/appConfig';
import { LocationMethods } from './mixins/locationHash';
// import clearTimeouts from './mixins/clearTimeouts';
var _ = require('lodash');
import axios from 'axios';
// import { set, setOptions, bootstrap } from 'vue-gtag';
import LandingPage from './components/LandingPage';
import GoogleLoginButton from './components/GoogleLoginButton';
import LoadingIndicator from './components/LoadingIndicator';
import SliderPanelAnimation from './components/SliderPanelAnimation';
import ToolWrapper from './components/ToolWrapper';
export default {
	name: 'App',
	components: {
		LandingPage,
		GoogleLoginButton,
		// ErrorIndicator,
		LoadingIndicator,
		// PageFadeAnimation,
		SliderPanelAnimation,
		ToolWrapper,
	},
	mixins: [LocationMethods],
	data() {
		return {
			usResidentCheckbox: false,
		};
	},
	computed: {
		...mapState(appConfig.config),
		...mapGetters({
			// Needed to handle logic at the state level
			getLocalStorage: getters.GET_LOCAL_STORAGE,
			getValidToken: getters.GET_VALID_TOKEN,
			getValidTokenLocal: getters.GET_VALID_TOKEN_LOCAL,
			getWindowLocationHashExists: getters.GET_WINDOW_LOCATION_HASH_EXISTS,
		}),
	},
	created() {
		// if local storage, setAppStage
		if (this.getLocalStorage && !this.acceptance) {
			this.setAppStage(0);
		}
	},
	async mounted() {
		console.debug('Welcome to gFilta, click the continue button to get started');
		const localStorage = this.getLocalStorage;
		const hashValid = this.getWindowLocationHashExists;
		if (localStorage) {
			console.debug('Previous data detected');
			console.debug('Local storage exists, setting app state');
			this.hashCleaner();
			await this.setAppState(localStorage);
			this.setUserDataLoaded(true); //TODO: may not need this
			this.setAppStage(3);
			this.setHeaderMessage(3);
			return;
		} else if (hashValid) {
			console.clear();
			console.debug('Redirect from Google OAuth, setting app state');
			await this.setOauthAccessToken(this.windowLocationHash);
			this.hashCleaner();
			this.setAppStage(2);
			this.setHeaderMessage(2);
			this.userDataHandler();
		} else {
			this.setAppStage(0);
			this.setHeaderMessage(0);
		}
	},
	unmounted() {
		console.clear();
	},
	methods: {
		...mapActions({
			setAcceptance: actions.SET_ACCEPTANCE,
			setActiveFilter: actions.SET_ACTIVE_FILTER,
			setActiveLabel: actions.SET_ACTIVE_LABEL,
			setActiveTool: actions.SET_ACTIVE_TOOL,
			setAnimeDirCoords: actions.SET_ANIME_DIR_COORDS,
			setAppStage: actions.SET_APP_STAGE,
			setAppState: actions.SET_APP_STATE,
			setEditorButtonsEnabled: actions.SET_EDITOR_BUTTONS_ENABLED,
			setFilters: actions.SET_FILTERS,
			setFiltersLoaded: actions.SET_FILTERS_LOADED,
			setHeaderMessage: actions.SET_HEADER_MESSAGE,
			// setIndexedLabelsArray: actions.SET_INDEXED_LABELS_ARRAY,
			setLabelNameArray: actions.SET_LABEL_NAME_ARRAY,
			setLabelIdArray: actions.SET_LABEL_ID_ARRAY,
			setLabelPathArray: actions.SET_LABEL_PATH_ARRAY,
			setLabels: actions.SET_LABELS,
			setLabelsLoaded: actions.SET_LABELS_LOADED,
			setLoadingMessage: actions.SET_LOADING_MESSAGE,
			setLocalStorage: actions.SET_LOCAL_STORAGE,
			setMessageTracker: actions.SET_MESSAGE_TRACKER,
			setOauthAccessToken: actions.SET_OAUTH_ACCESS_TOKEN,
			setTypeaheadPreload: actions.SET_TYPEAHEAD_PRELOAD,
			setUserDataLoaded: actions.SET_USER_DATA_LOADED,
			setUserEmail: actions.SET_USER_EMAIL,
			setUserEmailLoaded: actions.SET_USER_EMAIL_LOADED,
		}),
		async getFiltersAll() {
			console.debug('getFiltersAll()');
			setTimeout(() => {
				this.setMessageTracker(2);
				this.setLoadingMessage();
			}, 2000);
			await axios
				.get(`${this.endpointBaseUrl}${this.endpointFilters}`, {
					headers: {
						Authorization: `Bearer ${this.oauthAccessToken}`,
					},
				})
				.then(
					(response) => {
						const filterData = response.data;
						let filters = filterData.filter.map((filter) => filter);
						let filtersFried = [];
						// let filterDropdown = filterData.filter.map((filter) => {
						// 	filter.criteria && filter.criteria.from ? filter.criteria.from.split(' OR ') : [];
						// });
						// this.setFiltersIndexedArray(filters);
						filters.forEach((filter) => {
							let currentFilter = {
								original: {
									action: {
										forward: filter.action?.forward || '',
										addLabelIds: filter.action?.addLabelIds || [],
										removeLabelIds: filter.action?.removeLabelIds || [],
									},
									criteria: {
										from: filter.criteria.from || '',
										excludeChats: filter.criteria.excludeChats || false,
										hasAttachment: filter.criteria.hasAttachment || false,
										negatedQuery: filter.criteria.negatedQuery || '',
										query: filter.criteria.query || '',
										size: filter.criteria.size || 0,
										sizeComparison: filter.criteria.sizeComparison || 'unspecified',
										subject: filter.criteria.subject || '',
										to: filter.criteria.to || '',
									},
									id: filter.id,
								},
								extraCrispy: {
									editorButtonsEnabled: false,
									cancelUpdate: false,
									action: {
										forward: filter.action?.forward,
										addLabelIds: filter.action?.addLabelIds || [],
										removeLabelIds: filter.action?.removeLabelIds,
									},
									criteria: {
										from: filter.criteria.from,
										excludeChats: filter.criteria.excludeChats,
										hasAttachment: filter.criteria.hasAttachment,
										negatedQuery: filter.criteria.negatedQuery,
										query: filter.criteria.query,
										size: filter.criteria.size,
										sizeComparison: filter.criteria.sizeComparison,
										subject: filter.criteria.subject,
										to: filter.criteria.to,
									},
									id: filter.id,
								},
							};
							filtersFried.push(currentFilter);
						});
						// Now we push an empty label to the end of the array, this won't be displayed but will be used for the "add label" button
						filtersFried.push({
							original: {
								action: {
									addLabelIds: [],
								},
								criteria: {
									from: '@test.at',
								},
								id: 'Dummy ID',
							},
							extraCrispy: {
								editorButtonsEnabled: true,
								cancelUpdate: true,
								action: {
									addLabelIds: [],
								},
								criteria: {
									from: '@test.at',
								},
								id: 'Dummy ID',
							},
						});
						this.setFilters(filtersFried);
						this.setFiltersLoaded(true);
					},
					(error) => {
						// this.errorHandler(`[FILTERSALL] ${error}`);
						return false;
					},
				);
		},
		async getLabelsAll() {
			console.debug('getLabelsAll()');
			setTimeout(() => {
				this.setMessageTracker(3);
				this.setLoadingMessage();
			}, 2000);
			await axios
				.get(`${this.endpointBaseUrl}${this.endpointLabels}`, {
					headers: {
						Authorization: `Bearer ${this.oauthAccessToken}`,
					},
				})
				.then(
					(response) => {
						setTimeout(() => {
							this.setMessageTracker(3);
							this.setLoadingMessage();
						}, 1000);
						const labelData = response.data.labels;
						let labels = labelData.filter((label) => label.id.substring(0, 5) === 'Label');
						let labelsArray = [];
						let labelIdArray = [];
						let labelNameArray = [];
						let labelPathArray = [];
						let typeaheadPreloadObjectArrays = {
							labelNames: [],
							labelIdArray: [],
							labelPathArray: [],
						};
						labels.forEach((label) => {
							let currentLabel = {
								original: {
									id: label.id,
									name: label.name,
									type: label.type,
								},
								extraCrispy: {
									editorButtonsEnabled: false,
									cancelUpdate: false,
									id: label.id,
									name: label.name,
									type: label.type,
								},
							};
							// TYPEAHEAD
							typeaheadPreloadObjectArrays.labelNames.push(label.name),
								typeaheadPreloadObjectArrays.labelIdArray.push(label.id),
								typeaheadPreloadObjectArrays.labelPathArray.push(label.name),
								// These will be removed once the typeaheadPreloadObject is in place
								labelNameArray.push(label.name),
								labelIdArray.push(label.id),
								labelPathArray.push(label.name),
								// LABELS
								labelsArray.push(currentLabel);
						});
						// Now we push an empty label to the end of the array, this won't be displayed but will be used for the "add label" button
						labelsArray.push({
							original: {
								id: '',
								name: '',
								type: '',
							},
							extraCrispy: {
								editorButtonsEnabled: true,
								cancelUpdate: true,
								placeholder: 'Give your label a name',
								id: '',
								name: '',
								type: '',
							},
						});
						this.setLabels(labelsArray);
						this.setTypeaheadPreload(typeaheadPreloadObjectArrays);
						this.setLabelIdArray(labelIdArray);
						this.setLabelNameArray(labelNameArray);
						this.setLabelPathArray(labelPathArray);
						this.setLabelsLoaded(true);
						this.setUserDataLoaded(true);
						setTimeout(() => {
							this.setAppStage(3);
							this.setHeaderMessage(3);
						}, 4000);
						return true;
					},
					(error) => {
						// this.errorHandler(`[LABELSALL] ${error}`);
						return false;
					},
				);
		},
		async getUserInfo() {
			console.debug('getUserInfo()');
			await axios
				.get(`${this.endpointBaseUrl}${this.endpointUserInfo}`, {
					headers: {
						Authorization: `Bearer ${this.oauthAccessToken}`,
					},
				})
				.then(
					(response) => {
						this.setMessageTracker(1);
						this.setLoadingMessage();
						const userData = response.data;
						this.setUserEmail(userData.emailAddress);
						this.setUserEmailLoaded(true);
						this.setHeaderMessage(3);

						return true;
					},
					(error) => {
						// this.errorHandler(`[USERINFOEMAIL] ${error}`);
						return false;
					},
				);
		},
		async setUserAcceptance() {
			this.setAcceptance(true);
			// TODO: if stage3, devMode, acceptance, and localstorage are all true, then we can set the app stage to 4 without setting the localstorage
			console.debug('Thank you for using gfilta, enjoy your time');
			this.setAppStage(4);
			setTimeout(() => {
				this.setLocalStorage({ ...this.$store.state });
			}, 2000);
		},
		async userDataHandler() {
			this.setMessageTracker(0);
			this.setLoadingMessage();
			setTimeout(async () => {
				await this.getUserInfo();
				await this.getFiltersAll();
				await this.getLabelsAll();
				this.setLoadingMessage();
			}, 3000);
		},
	},
};
</script>
<style lang="less">
/** LESS VARS */
:root {
	/* Google Brand Colors */
	// NEED TO VALIDATE COLORS
	// --google-blue: #4285f4;
	// --google-green: #34a853;
	// --google-yellow: #fbbc05;
	// --google-red: #ea4335;
	// --size: 5px;

	--PRIMARY_WHITE: #ffffff;
	--PRIMARY_BLUE: #2363eb;
	--PRIMARY_GREEN: #34a853;
	--PRIMARY_RED: #ea4335;
	--PRIMARY_YELLOW: #fbbc05;
	--PRIMARY_GRAY: #e0e0e0;
	--PRIMARY_GRAY_X11: #babcbe;
	--PRIMARY_GRAY_DAVYS: #535353;
	--PRIMARY_GRAY_DARK: #666666;
	--PRIMARY_BORDER_SOLID_WHITE: 1px solid var(--PRIMARY_WHITE);
	--PRIMARY_BORDER: 1px solid var(--PRIMARY_GRAY);
	--PRIMARY_BORDER_DOTTED: 1px dotted var(--PRIMARY_GRAY);
	--PRIMARY_BORDER_RADIUS: 8px;
	--PRIMARY_LOADING_INDICATOR_DOT_SIZE: 5px;
}
/** DOM */
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-size: 9px;
	font-weight: normal;
}
body {
	margin: 0;
	height: 100%;
	overflow: hidden;
	padding: 0;
	font-size: 1.6rem;
	font-family: Roboto, sans-serif;
	color: #222;
}
#page-header {
	position: absolute;
	margin: 0;
	top: 0px;
	left: 0px;
	height: 128px;
	width: 100%;
	background-color: var(--PRIMARY_WHITE);
	padding: 0;
	text-align: center;
	z-index: 9998;
}
/** LOGO */
#site-header-logo-container {
	width: 100%;
	height: 55px;
	text-align: center;
}
#site-header-logo-container > svg,
#site-header-logo-container > path {
	width: 128px;
	height: 55px;
}
.st0 {
	fill: url(#SVGID_1_);
}
.st1 {
	fill: url(#SVGID_00000095308337247621760350000007828642972061234824_);
}
.st2 {
	fill: url(#SVGID_00000055703712468548122730000004103840800477150634_);
}
.st3 {
	fill: url(#SVGID_00000182496137791100641860000014273464882179253400_);
}
#page-header > h1 {
	font-size: 24px;
	font-weight: 700;
	color: var(--PRIMARY_GRAY_DAVYS);
	margin: 4px 0 4px 0;
	padding: 0;
}
#page-header > h2 {
	font-size: 18px;
	font-weight: 400;
	color: var(--PRIMARY_GRAY_DAVYS);
	margin: 4px 0 0 0;
	padding: 0;
}
#main {
	position: absolute;
	top: 128px;
	left: 0;
	height: calc(100% - 128px);
	width: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
}
a {
	text-decoration: none;
	color: inherit;
}
.slider {
	box-sizing: border-box;
	padding-top: 10px;
}
#message-all-set {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	text-align: center;
}
.message-all-set-section-1 {
	margin-top: 8px;
}
.message-all-set-section-1 > h3 {
	font-size: 20px;
	font-weight: 600;
	color: var(--PRIMARY_WHITE);
}
.message-all-set-section-2 {
	margin-top: 22px;
}
.message-all-set-section-2 > h4 {
	font-size: 20px;
	font-weight: 600;
	color: var(--PRIMARY_WHITE);
}
.message-all-set-section-3 {
	margin-top: 22px;
	font-size: 22px;
	font-weight: 600;
	color: var(--PRIMARY_YELLOW);
}
.message-all-set-section-4 {
	margin-top: 22px;
}
.message-all-set-section-4 > h5 {
	font-size: 20px;
	font-weight: 600;
	color: var(--PRIMARY_WHITE);
}
.message-all-set-section-5 {
	margin-top: 22px;
}
.message-all-set-section-5 > ul > li {
	list-style-type: none;
	padding-top: 4px;
}
.message-all-set-section-6 > h5 {
	margin-top: 22px;
	font-size: 18px;
	font-weight: normal;
	color: var(--PRIMARY_WHITE);
}
.message-all-set-section-7 {
	margin-top: 22px;
}
.message-all-set-section-8 {
	margin-top: 22px;
}
#start-organizing-button {
	margin-top: 16px;
	// background-color: var(--PRIMARY_WHITE);
	border-radius: 8px;
	cursor: pointer;
}
.content-button {
	flex: 1;
	margin-top: 20px;
}
.landing-page-content-button {
	flex: 1;
	background-color: var(--PRIMARY-WHITE);
	border-radius: 5px;
	padding: 0.6rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-in-out;
	cursor: pointer !important;
	border: none;
	font-size: 1.8rem;
	color: var(--PRIMARY-BLUE);
	font-weight: bold;
}
.landing-button:hover {
	box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
	animation: grow 100ms;
	animation-timing-function: ease-in-out;
	transform: scale(1.5);
}
@keyframes grow {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.5);
	}
}
</style>
