<template>
	<div :class="sliderClass">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'SliderPanelAnimation',
	components: {},
	props: {
		animateConfig: Object,
		background: String,
	},
	data() {
		return {
			// TODO: Chicken McNugget. Move this to a state
			animateConfig2: this.animateConfig,
			slideDirections: ['left', 'right', 'up', 'down'],
			x: this.animateConfig?.x, // Start
			y: this.animateConfig?.y, // End
		};
	},
	computed: {
		sliderClass() {
			return `slider slider-panel-animation slider-${this.x}-${this.y} primary-${this.background}-background`;
		},
	},
};
</script>

<style lang="less">
/**
x:
Origin of the sliding panel:
Top being above the view area

y:
Destination of the sliding panel:
Center being the view area 
*/

.slider {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 9997;
	animation-fill-mode: forwards;
}
#slider-04 {
	/** Used instead of an extra tool-wrapper-div */
	display: flex;
}
.primary-white-background {
	background-color: var(--PRIMARY_WHITE);
}
.primary-blue-background {
	background-color: var(--PRIMARY_BLUE);
}
.primary-green-background {
	background-color: var(--PRIMARY_GREEN);
}
.primary-red-background {
	background-color: var(--PRIMARY_RED);
}
.primary-yellow-background {
	background-color: var(--PRIMARY_YELLOW);
}
/** Animation Naming Convention:
 * slider-direction-end
 */
/** Animation */
.slider-top-center {
	top: -100vh;
	animation: top-center 2s;
} /** IN */
@keyframes top-center {
	/**
    [ ][v][ ]
    [ ][*][ ]
    [ ][ ][ ]
*/
	from {
		top: -100vh;
		.body {
			overflow: visible;
		}
	}
	to {
		top: 0;
		.body {
			overflow: hidden;
		}
	}
}
.slider-top-center {
	// Reset
	top: 0;
}

.slider-up-top {
	top: 0;
	animation: up-top 2s;
} /** IN */
@keyframes up-top {
	/**
    [ ][*][ ]
    [ ][^][ ]
    [ ][ ][ ]
*/
	from {
		top: 0;
		.body {
			overflow: visible;
		}
	}
	to {
		top: -100vh;
		.body {
			overflow: hidden;
		}
	}
}
.slider-up-top {
	top: -100vh;
}

.slider-up-center {
	top: 100vh;
	animation: up-center 2s;
} /** IN */
@keyframes up-center {
	/**
    [ ][ ][ ]
    [ ][*][ ]
    [ ][^][ ]
*/
	from {
		top: 100vh;
		.body {
			overflow: visible;
		}
	}
	to {
		top: 0;
		.body {
			overflow: hidden;
		}
	}
}
.slider-up-center {
	top: 0;
}
//TODO: convert lefts and tops to transform translate
.slider-left-center {
	left: 100vh;
	animation: left-center 2s;
} /** IN */
@keyframes left-center {
	/**
    [ ][ ][ ]
    [ ][*][<]
    [ ][ ][ ]
*/
	from {
		left: 100vh;
		.body {
			overflow: visible;
		}
	}
	to {
		left: 0;
		.body {
			overflow: hidden;
		}
	}
}
.slider-left-center {
	left: 0;
}
.slider-right-center {
	left: -100vh;
	animation: right-center 2s;
} /** IN */
@keyframes right-center {
	/**
    [ ][ ][ ]
    [>][*][ ]
    [ ][ ][ ]
*/
	from {
		left: -100vh;
		.body {
			overflow: visible;
		}
	}
	to {
		left: 0;
		.body {
			overflow: hidden;
		}
	}
}
.slider-right-center {
	left: 0;
}
</style>
