<template>
	<div @click="showDropdown()" class="dropdown">
		<div class="dropdown-selection">{{ defaultOption }}</div>
		<div
			v-show="dropdownOpen && !dropdownHide"
			:class="`dropdown-content dropdown-content-${dropdownContentClass}`">
			<div v-for="(option, index) in getOptions" :key="index" class="dropdown-option">
				{{ option }}
				<div @click="deleteOption(idx, index)" class="dropdown-option-delete-button">
					<font-awesome-icon
						icon="fa-solid fa-circle-xmark"
						:id="`dropdown-option-delete-button-icon-${index}}`"
						class="dropdown-option-delete-button-icon"
						size="1x" />
				</div>
			</div>
			<input
				v-model="newOption"
				@click="addOption(idx)"
				@keyup.enter="addOption(idx)"
				:id="`dropdown-option-input-${idx}`"
				class="dropdown-option dropdown-option-input"
				placeholder="Add a new adress@somedomain.com OR @somedomain.com" />
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as actions from '../store/actions/actions';
import dropdownConf from '../store/maps/dropdownConf';
export default {
	name: 'DropdownComponent',
	props: {
		idx: {
			type: Number,
		},
	},
	data() {
		return {
			selectedOption: null, // to keep track of the selected option
			newOption: '', // to keep track of the new option
			lastOption: null, // to keep track of the last option
			dropdownOpen: false, // to keep track of the dropdown open state
			dropdownContentClass: 'hide', // to keep track of the dropdown content class show/hide
		};
	},
	mounted() {
		document.addEventListener('click', this.onClickOutside.bind(this));
	},
	beforeUnmount() {
		document.removeEventListener('click', this.onClickOutside);
	},
	computed: {
		...mapState(dropdownConf.config),
		defaultOption() {
			return this.filters[this.idx].extraCrispy.criteria.from;
		},
		getOptions() {
			return this.filters[this.idx].extraCrispy.criteria.from
				? this.filters[this.idx].extraCrispy.criteria.from.toString().split(' OR ')
				: [];
		},
	},
	methods: {
		...mapActions({
			setEditorButtonsEnabled: actions.SET_EDITOR_BUTTONS_ENABLED,
			setDropdownHide: actions.SET_DROPDOWN_HIDE,
			setFilterExtraCrispyCriteriaFrom: actions.SET_FILTER_EXTRA_CRISPY_CRITERIA_FROM,
		}),
		async addOption(idx) {
			console.debug('addOption');
			if (this.newOption.trim()) {
				let currentOptions = this.filters[idx].extraCrispy.criteria.from.toString();
				this.newOption.trim().length > 0 ? (currentOptions += ' OR ' + this.newOption.trim()) : false;
				await this.setFilterExtraCrispyCriteriaFrom({
					// Assuming this is an async function
					tool: 'filters',
					criteria: { from: currentOptions },
					idx: idx,
				});
				this.setEditorButtonsEnabled({ tool: 'filters', idx: idx });
				this.newOption = '';
			}
		},
		async deleteOption(idx, option) {
			console.debug('deleteOption');
			let currentOptions = this.filters[idx].extraCrispy.criteria.from.toString().split(' OR ');
			currentOptions.splice(option, 1);
			currentOptions = currentOptions.join(' OR ');
			this.setFilterExtraCrispyCriteriaFrom({
				tool: 'filters',
				criteria: { from: currentOptions },
				idx: idx,
			});
			const result = await this.setEditorButtonsEnabled({ tool: 'filters', idx: idx });
			// console.debug(result); // Should log: { editorButtonsEnabled: true } or { editorButtonsEnabled: false }
			this.newOption = '';
		},
		hideDropdown() {
			console.debug('hideDropdown');
			this.dropdownOpen = false;
			this.dropdownContentClass = 'hide';
			this.setDropdownHide(true);
		},
		showDropdown() {
			console.debug('showDropdown');
			this.dropdownOpen = true;
			this.dropdownContentClass = 'show';
			this.setDropdownHide(false);
		},
		onClickOutside(event) {
			if (this.dropdownOpen && !this.$el.contains(event.target)) {
				this.hideDropdown();
			}
		},
	},
};
</script>

<style lang="less">
.dropdown {
	height: 100%;
	width: inherit;
	display: flex;
	flex-direction: column;
}
.dropdown-selection {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: inherit;
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	border-radius: 0 4px 4px 0;
	cursor: pointer;
}
.dropdown-selection:hover {
	background-color: var(--PRIMARY_BLUE);
	border-radius: 0;
	color: var(--PRIMARY_WHITE);
}
.dropdown-content {
	width: inherit;
	flex-direction: column;
	background-color: var(--PRIMARY_WHITE);
}
.dropdown-content-show {
	display: flex;
	margin-top: 2px;
}
.dropdown-option {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
	width: inherit;
	padding: 2px;
	background-color: var(--PRIMARY_GRAY);
	border: 1px solid #ddd;
	cursor: pointer;
}
.dropdown-option:hover {
	background-color: var(--PRIMARY_BLUE);
	color: var(--PRIMARY_WHITE);
}
.dropdown-option-input {
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	border: 1px solid var(--PRIMARY_GRAY);
	background-color: var(--PRIMARY_WHITE);
}
.dropdown-option-delete-button {
	color: var(--PRIMARY_RED);
}
</style>
