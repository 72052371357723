import { createStore } from 'vuex';
import * as getters from './getters/getters';
import * as actions from './actions/actions';
import { Error } from '../mixins/errorHandler';
import { StageLogic } from '../mixins/appStageHandler';
import ApiService from '../utils/apiService';
import * as _ from 'lodash';
const apiService = new ApiService('https://gmail.googleapis.com/gmail/v1/users/me');
// TODO: Error reporting
// TODO: Better token validation
const store = createStore({
	state: {
		animeCoords: { start: '', end: '' }, // Used to store the start/end coordinates of the anime element
		acceptance: false, // Used to track if the user has accepted the terms
		activeFilter: {}, // Active filter data object taken from state.labels
		activeLabel: {}, // Active filter data object taken from state.labels
		activeTool: null, // Active tool
		appStage: 0, // Used to track the stage of the app
		devMode: true, // Used to toggle dev mode in conjunction to existing state in local storage
		dragToggle: false, // Used to toggle the drag functionality
		dropdownHide: true, // Used to hide the dropdown menu
		error: false, // Toggle error;
		errorMessage: '', // Error message
		filter: false, // Used to track if the user is creating a new filter
		filterApprove: false, // Used to track if the user is approving a filter
		filterDelete: false, // Used to track if the user is deleting a filter
		filterLoadingExisting: false, // Used to track if the user is creating a new filter
		filterLoadingNew: false, // Used to track if the user is creating a new filter
		filterNew: false, // Used to track if the user is creating a new filter
		filterNewInputValue: '', // Used to track if the user is creating a new filter
		filterNotification: false, // Used to hide the filter notification
		filterToggle: false, // Used to toggle the filter tool
		filters: [], // User's filters retrieved from GCP after Oauth
		// filterIndexedArray: [], // Array of indexed objects for filter criteria-from dropdown
		filtersLoaded: false, // True once loaded
		headerMessage: '', // Header message
		// indexedLabelsArray: [], // Array of indexed objects for labels
		key: '', // TODO: Was this used as a key for Vue components? If not, remove - NOTE: Tool Stage
		labelApprove: false, // Used to track if the user is creating a new label
		labelDelete: false, // Used to track if the user is deleting a label
		labelIdArray: [], // Array of label IDs for typeahead
		labelLoadingExisting: false, // Used to track if the user is creating a new label
		labelLoadingNew: false, // Used to track if the user is creating a new label
		labelNameArray: [], // Array of labels names for typeahead
		labelNew: false, // Used to track if the user is creating a new label
		labelNotification: false, // Used to hide the filter notification
		labelPathArray: [], // Array of label paths for typeahead
		labelToggle: false, // Used to toggle the filter tool
		labels: [], // User's labels retrieved from GCP after Oauth
		labelsLoaded: false, // True once loaded
		loadingIndicator: false, // Toggle loading indicator
		loadingMessage: '', // Loading message
		messagesObj: {
			zero: 'Welcome', // Message displayed in header element
			one: 'Please Login', // Message displayed in header element
			two: 'Welcome ', // Message displayed in header element
			three: 'Please hold while we gather your labels and filters', // Loading message 0
			four: 'Fetching your filters...', // Loading message 1
			five: 'Fetching your labels...', // Loading message 2
			six: 'Wrapping up...', // Loading message 3
		},
		messageTracker: 0, // Used to track the message index
		oauthAccessToken: false, // Used to store the access token after Oauth
		redirect: false, // Tracker used to identify if the user is being redirected back from GCP
		redirectCountDown: 5, // Count to display
		redirectTimer: null, // Interval storage
		suggestions: [], // Single suggestion for the typeahead input
		toolView: 'list', // Used to track the view of the tool - list/cells
		toolViewLabel: 'list', // Used to track the view of the label tool - list/cells
		toolViewFilter: 'list', // Used to track the view of the filter tool - list/cells
		typeaheadPreloadObject: {}, // Index of the typeahead suggestions
		typeaheadSuggestions: [], // Suggestions array for the typeahead input
		userDataLoaded: false, // True once loaded
		userEmail: '', // User email retrieved from GCP after Oauth
		userEmailLoaded: false, // True once loaded
		windowLocationHash: window.location.hash, // Used to clean the location after Oauth
		windowLocationHashExists: false, // Used to track if the window location hash exists
		// ENDPOINTS
		endpointBaseUrl: 'https://gmail.googleapis.com/', // Base URL for all endpoints label/filter related
		endpointFilters: 'gmail/v1/users/me/settings/filters',
		endpointLabels: 'gmail/v1/users/me/labels',
		endpointStripeCheckout: 'https://buy.stripe.com/test_3csg2ubUN8Vh3OEcMM', // GET
		endpointUserInfo: 'gmail/v1/users/me/profile', // GET
	},
	namespaced: true,
	getters: {
		[getters.GET_LOCAL_STORAGE]: (state) => JSON.parse(window.localStorage.getItem('state')),
		[getters.GET_VALID_TOKEN]: (state) => (state.oauthAccessToken !== false ? true : false),
		[getters.GET_VALID_TOKEN_LOCAL]: (state) => (state.oauthAccessToken ? true : false),
		[getters.GET_WINDOW_LOCATION_HASH_EXISTS]: (state) =>
			state.windowLocationHash.substring(state.windowLocationHash.indexOf('=') + 1) ? true : false,
	},
	mutations: {
		delLocalStorage(control) {
			/**
			 * Remove localStorage(Separate objects) of the user data and appStage
			 *
			 * control is boolean
			 * true deletes localStorage of user data
			 * false sets localStorage of appStage
			 * localStorage is necessary for appStage due to redirect
			 *
			 * // TODO: Tie in error reporting
			 */
			if (control) {
				window.localStorage.removeItem('gfilta-data');
				if (!window.localStorage.getItem('gfilta-data')) {
					console.debug('gfilta-data deleted');
					return true;
				} else {
					console.debug('Error deleting gfilta-data from localStorage');
					return false;
				}
			} else {
				window.localStorage.removeItem('appStage');
				if (!window.localStorage.getItem('appStage')) {
					console.debug('appStage deleted');
					return true;
				} else {
					console.debug('Error deleting appStage from localStorage');
					return false;
				}
			}
		},
		setAcceptance: (state, accepted) => (state.acceptance = accepted),
		setActiveFilter: (state, idx) => (state.activeFilter = state.filters[idx]),
		setActiveLabel: (state, storeUpdate) =>
			storeUpdate === 696969 || storeUpdate === 424242
				? (state.activeLabel = false)
				: (state.activeLabel = state.labels[storeUpdate]),
		setActiveTool: (state, activeTool) => (state.activeTool = activeTool),
		setAnimeDirCoords: (state, coords) => (state.animeUpdate = coords),
		setAppStage: (state, stage) => (state.appStage = stage),
		setAppState: (state, stateUpdate) => {
			const newState = stateUpdate;
			for (let property in newState) {
				Object.prototype.hasOwnProperty.call(newState, property)
					? (state[property] = newState[property])
					: false;
			}
			Object.assign(state, newState);
		},
		setDropdownHide: (state, hide) => (state.dropdownHide = hide),
		setDevMode: (state, mode) => (console.debug('Updating devMode'), (state.devMode = mode)),
		setDragToggle: (state, dragToggle) => (state.dragToggle = dragToggle),
		setEditorButtonsEnabled: (state, { tool, idx }) => {
			if (state[tool][idx]) {
				let original, extraCrispy;
				if (tool === 'labels') {
					const { id: originalId, name: originalName, type: originalType } = state[tool][idx].original;
					original = { id: originalId, name: originalName, type: originalType };
					const {
						id: extraCrispyId,
						name: extraCrispyName,
						type: extraCrispyType,
					} = state[tool][idx].extraCrispy;
					extraCrispy = { id: extraCrispyId, name: extraCrispyName, type: extraCrispyType };

					if (extraCrispy.name) {
						state[tool][idx].extraCrispy.editorButtonsEnabled = _.isEqual(original, extraCrispy)
							? false
							: true;
					} else {
						state[tool][idx].extraCrispy.editorButtonsEnabled = false;
					}
				} else if (tool === 'filters') {
					const { criteria: originalCriteria } = state[tool][idx].original;
					const { criteria: extraCrispyCriteria } = state[tool][idx].extraCrispy;
					original = { addLabelIds: originalCriteria.addLabelIds, from: originalCriteria.from };
					extraCrispy = { addLabelIds: extraCrispyCriteria.addLabelIds, from: extraCrispyCriteria.from };
					// console.debug('original', original);
					// console.debug('extraCrispy', extraCrispy);
					// console.debug('isEqual', _.isEqual(original, extraCrispy));
					state[tool][idx].extraCrispy.editorButtonsEnabled = _.isEqual(original, extraCrispy) ? false : true;
					// console.debug(state[tool][idx].extraCrispy.editorButtonsEnabled);
				}
				if (!original || !extraCrispy) {
					// handle case where original or extraCrispy does not exist
					console.error('original or extraCrispy is not defined');
				}
			} else {
				// handle case where state[tool][idx] does not exist
				console.error('state[tool][idx] is not defined');
			}
		},
		// FILTERS
		setFilterCancel: (state, idx) => {
			state.filterNew = false;
			state.filterNewLoading = false;
			state.filters[idx].original.action.addLabelIds = state.filters[idx].extraCrispy.action.addLabelIds;
			state.filters[idx].extraCrispy.criteria.from = state.filters[idx].original.criteria.from;
			state.filters[idx].extraCrispy.id = state.filters[idx].original.id;
			idx < state.filters.length - 1
				? (state.filters[idx].extraCrispy.editorButtonsEnabled = false)
				: (state.filters[idx].extraCrispy.editorButtonsEnabled = true);
		},
		setFilterDelete: async (state, idx) => {
			let filterId;
			if (typeof idx === 'number') {
				filterId = state.filters[idx].original.id.toString();
			} else if (typeof idx === 'string') {
				filterId = idx;
			} else {
				throw new Error(`Invalid type for idx: ${typeof idx}`);
			}

			apiService
				.filterDelete({
					endpoint: `${state.endpointBaseUrl}${state.endpointFilters}`,
					id: filterId,
					token: state.oauthAccessToken,
				})
				.then((response) => {
					if (typeof idx === 'number') {
						// Returns no data in body on success
						state.filters.splice(idx, 1);
					}
				});
		},
		setFilterExtraCrispyCriteriaFrom: (state, filterExtraCrispyCriteriaFrom) => {
			const filter = state.filters[filterExtraCrispyCriteriaFrom.idx];
			if (filter && filter.extraCrispy && filter.extraCrispy.criteria) {
				filter.extraCrispy.criteria.from =
					filterExtraCrispyCriteriaFrom.criteria.from !== undefined
						? filterExtraCrispyCriteriaFrom.criteria.from
						: '';
			} else {
				// console.error('Invalid filter object at index ', filterExtraCrispyCriteriaFrom.idx);
			}
		},
		setFilterExtraCrispyLabelIds: (state, { labelId, filterIdx }) => {
			let labelIdsArray = state.filters[filterIdx].original.action.addLabelIds;
			labelIdsArray.push(labelId);
			state.filters[filterIdx].extraCrispy.action.addLabelIds = labelIdsArray;
		},
		setFilterLoadingExisting: (state, idx) => {
			console.debug('setFilterLoadingExisting()');
			console.debug('filterLoading: ', idx || false);
			state.filterLoadingExisting = idx;
		},
		setFilterLoadingNew: (state, filterLoadingNew) => (state.filterLoadingNew = filterLoadingNew),
		setFilterNew: (state, filterNew) => (state.filterNew = filterNew),
		setFilterNewInputValue: (state, filterNewInputValue) => (state.filterNewInputValue = filterNewInputValue),
		setFilterNotification: (state, idx) => (state.filterNotification = idx),
		setFilterOriginalCriteriaFrom: (state, filterOriginalCriteriaFrom) => {
			const filter = state.filters[filterOriginalCriteriaFrom.idx];
			filter.original.criteria.from = filterOriginalCriteriaFrom.criteria.from;
		},
		// setFilterRemove: (state, idx) => {
		// 	state.filters.splice(idx, 1);
		// },
		setFilterReplace: (state, { idx, filterNew }) => {
			state.filters.splice(idx, 1, filterNew);
		},
		setFilterToggle: (state, filterToggle) => (state.filterToggle = filterToggle),
		setFilters: (state, GCPfilterArray) => {
			state.filters = GCPfilterArray;
		},
		setFiltersLoaded: (state, loaded) => (state.filtersLoaded = loaded),
		// HEADER MESSAGE
		setHeaderMessage: (state) => {
			if (state.appStage === 0) {
				// Welcome
				console.debug('Welcome');
				state.headerMessage = state.messagesObj.zero;
			} else if (state.appStage === 1) {
				// Please Login
				console.debug('Please Login');
				state.headerMessage = state.messagesObj.one;
			} else if (state.appStage === 2 || state.appStage === 3) {
				// Welcome userEmail
				console.debug(state.userEmail ? 'Hi ' + state.userEmail : 'Welcome');
				state.headerMessage = state.messagesObj.two + state.userEmail;
			} else if (state.appStage === 4) {
				// userEmail
				console.debug(state.userEmail);
				state.headerMessage = state.userEmail;
			} else {
				state.headerMessage = 'Message not found';
			}
		},
		// LABELS
		setLabelCancel: (state, idx) => {
			state.labelNew = false;
			state.labelNewLoading = false;
			state.labels[idx].extraCrispy.name = state.labels[idx].original.name;
			state.labels[idx].extraCrispy.id = state.labels[idx].original.id;
			idx < state.labels.length - 1
				? (state.labels[idx].extraCrispy.editorButtonsEnabled = false)
				: (state.labels[idx].extraCrispy.editorButtonsEnabled = true);
		},
		setLabelDelete: async (state, idx) => {
			apiService.labelDelete({
				endpoint: `${state.endpointBaseUrl}${state.endpointLabels}`,
				// Length is an override to pass a filter id directly to the api
				id: idx.length > 6 ? idx.toString() : state.labels[idx].original.id.toString(),
				token: state.oauthAccessToken,
			});
		},
		setLabelExtraCrispy: (state, labelExtraCrispy) =>
			(state.labels[labelExtraCrispy.idx].extraCrispy.name = labelExtraCrispy.name),
		setLabelIdArray: (state, labelIdArray) => (state.labelIdArray = labelIdArray),
		setLabelIdArrayItem: (state, { idx, labelIdArrayItem }) => {
			state.labelIdArray.splice(idx, 1, labelIdArrayItem);
		},
		setLabelLoadingExisting: (state, idx) => {
			state.labelLoadingExisting = idx;
		},
		setLabelLoadingNew: (state, labelLoadingNew) => (state.labelLoadingNew = labelLoadingNew),
		setLabelNameArray: (state, labelNameArray) => (state.labelNameArray = labelNameArray),
		setLabelNameArrayItem: (state, { idx, labelNameArrayItem }) => {
			state.labelNameArray.splice(idx, 1, labelNameArrayItem);
		},
		setLabelNew: (state, labelNew) => (state.labelNew = labelNew),
		setLabelNewInputReset: (state, idx) => {
			state.labels[idx].original.id = state.labels[idx].extraCrispy.id;
			state.labels[idx].original.name = state.labels[idx].extraCrispy.name;
			state.labels[idx].original.path = state.labels[idx].extraCrispy.path;
		},
		setLabelNewUpdate: async (state, labelNew) => {
			// Fixes name value which carries over after new label creation.
			state.labels[state.labels.length - 1].extraCrispy.id = state.labels[state.labels.length - 1].original.id;
			state.labels[state.labels.length - 1].extraCrispy.name =
				state.labels[state.labels.length - 1].extraCrispy.placeholder;
			state.labels[state.labels.length - 1].extraCrispy.type =
				state.labels[state.labels.length - 1].original.type;

			state.labels = [{ ...labelNew }, ...state.labels];
			state.labelIdArray = [labelNew.original.id, ...state.labelIdArray];
			state.labelNameArray = [labelNew.original.name, ...state.labelNameArray];
			state.labelPathArray = [labelNew.original.name, ...state.labelPathArray];
		},
		setLabelPathArray: (state, labelPathArray) => (state.labelPathArray = labelPathArray),
		setLabelPathArrayItem: (state, { idx, labelPathArrayItem }) => {
			state.labelPathArray.splice(idx, 1, labelPathArrayItem);
		},
		setLabelRemove: (state, idx) => {
			console.debug('setLabelRemove()');
			console.debug('idx: ', idx);
			state.labels.splice(idx, 1);
			state.labelIdArray.splice(idx, 1);
			state.labelNameArray.splice(idx, 1);
			state.labelPathArray.splice(idx, 1);
		},
		setLabelReplace: (state, { idx, labelUpdate }) => {
			state.labels.splice(idx, 1, labelUpdate);
		},
		setLabelToggle: (state, labelToggle) => (state.labelToggle = labelToggle),
		setLabels: (state, GCPlabelsArray) => (state.labels = GCPlabelsArray),
		setLabelsLoaded: (state, loaded) => (state.labelsLoaded = loaded),
		setLoadingMessage(state) {
			if (state.appStage === 2) {
				if (state.messageTracker === 0) {
					console.debug('Please wait while we gather your labels and filters...');
					state.loadingMessage = state.messagesObj.three;
				} else if (state.messageTracker === 1) {
					console.debug('Fetching your filters...');
					state.loadingMessage = state.messagesObj.four;
				} else if (state.messageTracker === 2) {
					console.debug('Fetching your labels...');
					state.loadingMessage = state.messagesObj.five;
				} else if (state.messageTracker === 3) {
					console.debug('Wrapping Up...');
					state.loadingMessage = state.messagesObj.six;
				} else {
					state.loadingMessage = '';
				}
			}
		},
		setLocalStorage: (state, localStorage) => {
			console.debug('setLocalStorage');
			window.localStorage.setItem('state', JSON.stringify(localStorage));
		},
		setMessageTracker: (state, idx) => (state.messageTracker = idx),
		setOauthAccessToken: (state, token) => {
			token.match(/#access_token=([^&]+)/) && token.match(/#access_token=([^&]+)/)[1]
				? ((state.oauthAccessToken = token.match(/#access_token=([^&]+)/)[1]), (state.appStage = 2))
				: (state.oauthAccessToken = null);
		},
		setToolView: (state, toolView) => console.debug('setToolView()'),
		setTypeaheadPreload: (state, typeaheadPreloadObjectArrays) =>
			(state.typeaheadPreloadObject = typeaheadPreloadObjectArrays),
		setTypeaheadSuggestions: (state, typeaheadSuggestions) => (state.typeaheadSuggestions = typeaheadSuggestions),
		setUserDataLoaded: (state, loaded) => (console.debug('setUserDataLoaded'), (state.userDataLoaded = loaded)),
		setUserEmail: (state, userEmail) => (state.userEmail = userEmail),
		setUserEmailLoaded: (state, loaded) => (state.userEmailLoaded = loaded),
	},
	actions: {
		[actions.GET_LABEL_ORIGINAL]: async ({ state, commit }, storeUpdate) => commit('getLabelOriginal', storeUpdate),
		[actions.GET_LABEL_EXTRA_CRISPY]: async ({ state, commit }, storeUpdate) =>
			commit('getLabelExtraCrispy', storeUpdate),
		[actions.SET_ACCEPTANCE]: async ({ state, commit }, storeUpdate) => commit('setAcceptance', storeUpdate),
		[actions.SET_ACTIVE_FILTER]: async ({ state, commit }, storeUpdate) => commit('setActiveFilter', storeUpdate),
		[actions.SET_ACTIVE_LABEL]: async ({ state, commit }, storeUpdate) => commit('setActiveLabel', storeUpdate),
		[actions.SET_ACTIVE_TOOL]: async ({ state, commit }, storeUpdate) => commit('setActiveTool', storeUpdate),
		[actions.SET_ANIME_DIR_COORDS]: async ({ state, commit }, storeUpdate) =>
			commit('setAnimeDirCoords', storeUpdate),
		[actions.SET_APP_STAGE]: async ({ state, commit }, stage) => commit('setAppStage', stage),
		[actions.SET_APP_STATE]: async ({ state, commit }, storeUpdate) => commit('setAppState', storeUpdate),
		[actions.SET_DEV_MODE]: async ({ state, commit }, storeUpdate) => commit('setDevMode', storeUpdate),
		[actions.SET_DRAG_TOGGLE]: async ({ state, commit }, storeUpdate) => commit('setDragToggle', storeUpdate),
		[actions.SET_DROPDOWN_HIDE]: async ({ state, commit }, storeUpdate) => commit('setDropdownHide', storeUpdate),
		[actions.SET_EDITOR_BUTTONS_ENABLED]: async ({ state, commit }, storeUpdate) => {
			commit('setEditorButtonsEnabled', storeUpdate);

			// Get the updated state after mutation has been committed
			const updatedExtraCrispy = state[storeUpdate.tool][storeUpdate.idx].extraCrispy;

			// Return the updated editorButtonsEnabled value
			return { editorButtonsEnabled: updatedExtraCrispy.editorButtonsEnabled };
		},
		// [actions.SET_EDITOR_BUTTONS_ENABLED]: async ({ state, commit }, storeUpdate) =>
		// 	commit('setEditorButtonsEnabled', storeUpdate),
		[actions.SET_FILTER_CANCEL]: async ({ state, commit }, storeUpdate) => commit('setFilterCancel', storeUpdate),
		[actions.SET_FILTER_CREATE]: async ({ state, commit }, gcpFilterRequestObject) =>
			apiService.filterCreate({
				endpoint: `${state.endpointBaseUrl}${state.endpointFilters}`,
				token: state.oauthAccessToken,
				data: gcpFilterRequestObject,
			}),
		[actions.SET_FILTER_DELETE]: async ({ state, commit }, storeUpdate) => commit('setFilterDelete', storeUpdate),
		[actions.SET_FILTER_EXTRA_CRISPY_CRITERIA_FROM]: async ({ state, commit }, storeUpdate) =>
			commit('setFilterExtraCrispyCriteriaFrom', storeUpdate),
		[actions.SET_FILTER_NOTIFICATION]: async ({ state, commit }, storeUpdate) =>
			commit('setFilterNotification', storeUpdate),
		[actions.SET_FILTER_ORIGINAL_CRITERIA_FROM]: async ({ state, commit }, storeUpdate) =>
			commit('setFilterOriginalCriteriaFrom', storeUpdate),
		[actions.SET_FILTER_EXTRA_CRISPY_LABEL_IDS]: async ({ state, commit }, storeUpdate) =>
			commit('setFilterExtraCrispyLabelIds', storeUpdate),
		[actions.SET_FILTER_NEW]: async ({ state, commit }, storeUpdate) => commit('setFilterNew', storeUpdate),
		[actions.SET_FILTER_NEW_INPUT_VALUE]: async ({ state, commit }, storeUpdate) =>
			commit('setFilterNewInputValue', storeUpdate),
		[actions.SET_FILTER_LOADING_NEW]: async ({ state, commit }, storeUpdate) =>
			commit('setFilterLoadingNew', storeUpdate),
		[actions.SET_FILTER_LOADING_EXISTING]: async ({ state, commit }, storeUpdate) =>
			commit('setFilterLoadingExisting', storeUpdate),
		[actions.SET_FILTER_REMOVE]: async ({ state, commit }, storeUpdate) => commit('setFilterRemove', storeUpdate),
		[actions.SET_FILTER_REPLACE]: async ({ state, commit }, { idx, filterNew }) =>
			commit('setFilterReplace', { idx, filterNew }),
		[actions.SET_FILTER_TOGGLE]: async ({ state, commit }, storeUpdate) => commit('setFilterToggle', storeUpdate),
		[actions.SET_FILTERS]: async ({ state, commit }, storeUpdate) => commit('setFilters', storeUpdate),
		[actions.SET_FILTERS_LOADED]: async ({ state, commit }, storeUpdate) => commit('setFiltersLoaded', storeUpdate),
		[actions.SET_HEADER_MESSAGE]: async ({ state, commit }, storeUpdate) => commit('setHeaderMessage', storeUpdate),
		[actions.SET_LABEL_CANCEL]: async ({ state, commit }, storeUpdate) => commit('setLabelCancel', storeUpdate),
		[actions.SET_LABEL_CREATE]: async ({ state, commit }, gcpLabelRequestObject) =>
			apiService.labelCreate({
				endpoint: `${state.endpointBaseUrl}${state.endpointLabels}`,
				token: state.oauthAccessToken,
				data: gcpLabelRequestObject,
			}),
		[actions.SET_LABEL_DELETE]: async ({ state, commit }, storeUpdate) => commit('setLabelDelete', storeUpdate),
		[actions.SET_LABEL_EXTRA_CRISPY]: async ({ state, commit }, storeUpdate) =>
			commit('setLabelExtraCrispy', storeUpdate),
		[actions.SET_LABEL_ID_ARRAY]: async ({ state, commit }, storeUpdate) => commit('setLabelIdArray', storeUpdate),
		[actions.SET_LABEL_ID_ARRAY_ITEM]: async ({ state, commit }, storeUpdate) =>
			commit('setLabelIdArrayItem', storeUpdate),
		[actions.SET_LABEL_LOADING_NEW]: async ({ state, commit }, storeUpdate) =>
			commit('setLabelLoadingNew', storeUpdate),
		[actions.SET_LABEL_LOADING_EXISTING]: async ({ state, commit }, storeUpdate) =>
			commit('setLabelLoadingExisting', storeUpdate),
		[actions.SET_LABEL_NAME_ARRAY]: async ({ state, commit }, storeUpdate) =>
			commit('setLabelNameArray', storeUpdate),
		[actions.SET_LABEL_NAME_ARRAY_ITEM]: async ({ state, commit }, storeUpdate) =>
			commit('setLabelNameArrayItem', storeUpdate),
		[actions.SET_LABEL_NEW]: async ({ state, commit }, storeUpdate) => commit('setLabelNew', storeUpdate),
		[actions.SET_LABEL_NEW_INPUT_RESET]: async ({ state, commit }, storeUpdate) =>
			commit('setLabelNewInputReset', storeUpdate),
		[actions.SET_LABEL_NEW_UPDATE]: async ({ state, commit }, storeUpdate) =>
			commit('setLabelNewUpdate', storeUpdate),
		[actions.SET_LABEL_PATH_ARRAY]: async ({ state, commit }, storeUpdate) =>
			commit('setLabelPathArray', storeUpdate),
		[actions.SET_LABEL_PATH_ARRAY_ITEM]: async ({ state, commit }, storeUpdate) =>
			commit('setLabelPathArrayItem', storeUpdate),
		[actions.SET_LABEL_REMOVE]: async ({ state, commit }, storeUpdate) => commit('setLabelRemove', storeUpdate),
		[actions.SET_LABEL_REPLACE]: async ({ state, commit }, { idx, labelUpdate }) =>
			commit('setLabelReplace', { idx, labelUpdate }),
		[actions.SET_LABEL_TOGGLE]: async ({ state, commit }, storeUpdate) => commit('setLabelToggle', storeUpdate),
		[actions.SET_LABEL_UPDATE]: async ({ state, commit }, storeUpdate) =>
			apiService.labelUpdate({
				endpoint: `${state.endpointBaseUrl}${state.endpointLabels}`,
				token: state.oauthAccessToken,
				data: storeUpdate,
			}),
		[actions.SET_LABELS]: async ({ state, commit }, storeUpdate) => commit('setLabels', storeUpdate),
		[actions.SET_LABELS_LOADED]: async ({ state, commit }, storeUpdate) => commit('setLabelsLoaded', storeUpdate),
		[actions.SET_LOCAL_STORAGE]: async ({ state, commit }, storeUpdate) => commit('setLocalStorage', storeUpdate),
		[actions.SET_LOADING_MESSAGE]: async ({ state, commit }, storeUpdate) =>
			commit('setLoadingMessage', storeUpdate),
		[actions.SET_MESSAGE_TRACKER]: async ({ state, commit }, storeUpdate) =>
			commit('setMessageTracker', storeUpdate),
		[actions.SET_OAUTH_ACCESS_TOKEN]: async ({ state, commit }, storeUpdate) =>
			commit('setOauthAccessToken', storeUpdate),
		[actions.SET_TOOL_VIEW]: async ({ state, commit }, storeUpdate) => commit('setToolView', storeUpdate),
		[actions.SET_TYPEAHEAD_PRELOAD]: async ({ state, commit }, storeUpdate) =>
			commit('setTypeaheadPreload', storeUpdate),
		[actions.SET_TYPEAHEAD_SUGGESTIONS]: async ({ commit }, storeUpdate) =>
			commit('setTypeaheadSuggestions', storeUpdate),
		[actions.SET_USER_DATA_LOADED]: async ({ state, commit }, storeUpdate) =>
			commit('setUserDataLoaded', storeUpdate),
		[actions.SET_USER_EMAIL]: async ({ state, commit }, storeUpdate) => commit('setUserEmail', storeUpdate),
		[actions.SET_USER_EMAIL_LOADED]: async ({ state, commit }, loaded) => commit('setUserEmailLoaded', loaded),
	},
});
(store.errorHandler = Error.methods.handler), (store.stageHandler = StageLogic.methods.switch);
export default store;
