import { mapState } from 'vuex';

export default {
	config: mapState([
		// APP
		'error', // Toggle error
		'errorMessage', // Initialized with empty string
		'filters', // User's filters retrieved from GCP after Oauth
		'labelNameArray', // Array of labels names for typeahead
		'labelIdArray', // Array of label IDs for typeahead
		'labelPathArray', // Array of label paths for typeahead
		'labels', // User's labels retrieved from GCP after Oauth
		'typeaheadSuggestions', // Suggestions array for the typeahead input
	]),
};
