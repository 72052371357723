<template>
	<div class="landing-page-content-wrapper">
		<div class="landing-page-content">
			<!-- Placeholder for video demonstration -->
			<!-- <div class="video-placeholder">
				<video controls height="350px" style="width: 100%">
					<source :src="videoSrc" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</div> -->
			<h1>Streamline Your Email Management with gFilta</h1>
			<div id="landing-content-1">
				<div>
					Welcome to gFilta, the ultimate email organization tool. Say goodbye to cluttered inboxes and spend
					less time searching for important emails. gFilta simplifies your email workflow, allowing you to
					focus on what matters most.
				</div>
				<div id="landing-content-image-01" class="image-placeholder">
					<!-- Image by <a href="https://pixabay.com/users/clker-free-vector-images-3736/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=28110">Clker-Free-Vector-Images</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=28110">Pixabay</a> -->
				</div>
			</div>
			<div id="landing-content-2">
				<div id="landing-content-image-02" class="image-placeholder">
					<!-- Image by <a href="https://pixabay.com/users/clker-free-vector-images-3736/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=23654">Clker-Free-Vector-Images</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=23654">Pixabay</a> -->
				</div>
				<div>
					<h2>Key Features:</h2>
					<ul>
						<li>
							Effortless Email Filtering: gFilta enables you to set up filters to automatically categorize
							your emails.
						</li>
						<li>
							Label Management Made Easy: Organize your emails with customizable labels for quick
							reference.
						</li>
						<li>
							Seamless Integration: Works seamlessly with your existing Gmail account, no complex setup
							required.
						</li>
					</ul>
				</div>
			</div>
			<h2>Experience the Difference</h2>
			<div id="landing-content-3">
				<div>
					Join thousands of satisfied users who have transformed their email management experience with
					gFilta. Whether you're a busy professional, a small business owner, or a freelancer, gFilta is the
					email assistant you need.
				</div>
				<div id="landing-content-image-03" class="image-placeholder">
					<!-- Image by <a href="https://pixabay.com/users/toufik_ntizi-2076208/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1331579">Toufik</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1331579">Pixabay</a> -->
				</div>
			</div>
			<h3>Get Started Today</h3>
			<div id="landing-content-4" class="cta-section">
				<div>
					Start managing your emails efficiently with gFilta. Sign up now and experience the productivity
					boost.
				</div>
				<div>
					<button @click="updateStage" class="landing-button">Continue</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'LandingPageComponent',
	components: {},
	props: {},
	data() {
		return {
			// videoSrc: require('@/assets/your-video.mp4'),
		};
	},
	computed: {},
	methods: {
		updateStage() {
			this.$emit('stage', 1);
			console.debug('Please click the Google button to login.');
		},
	},
};
</script>
<style lang="less">
#main {
	overflow: scroll !important;
}
#slider-00 {
	height: auto !important;
}
.landing-page-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-top: 8px;
}
.landing-page-content > h1 {
	font-size: 20px;
	font-weight: 600;
	color: var(--PRIMARY_WHITE);
}
.landing-page-content > h2 {
	margin-top: 20px;
	font-size: 18px;
	font-weight: 600;
	color: var(--PRIMARY_YELLOW);
}
.landing-page-content > h3 {
	margin-top: 20px;
	font-size: 18px;
	font-weight: 600;
	color: var(--PRIMARY_WHITE);
}
#landing-content-1 {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
}
#landing-content-2 {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
}
#landing-content-3 {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
	min-height: 150px;
}
#landing-content-4 {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}
#landing-content-4 > div:nth-child(2) {
	display: flex;
	flex-direction: column;
	margin: 20px 0 20px 0;
	padding: 8px;
}
#landing-content-1 > div {
	flex: 1;
	max-width: 540px;
	margin-right: 8px;
	font-size: 14pt;
	text-align: left;
	color: var(--PRIMARY_YELLOW);
	// color: var(--PRIMARY_GRAY_DAVYS); // Need Yet another darker gray that is near black, as this is washed out against color
}
#landing-content-2 > div {
	flex: 1;
	max-width: 500px;
	margin-right: 8px;
	font-size: 14pt;
	text-align: left;
	// color: var(--PRIMARY_GRAY_DAVYS); // Need Yet another darker gray that is near black, as this is washed out against color
}
#landing-content-2 > div > h2 {
	margin-bottom: 8px;
	font-size: 16px;
	font-weight: 600;
	color: var(--PRIMARY_WHITE);
}
#landing-content-2 > div > ul {
	margin-bottom: 8px;
	font-size: 14pt;
	font-weight: 600;
	color: var(--PRIMARY_WHITE);
}
#landing-content-2 > div > ul > li {
	padding: 4px;
}
#landing-content-3 > div {
	flex: 1;
	max-width: 540px;
	margin-right: 8px;
	font-size: 14pt;
	text-align: left;
	color: var(--PRIMARY_WHITE);
	// color: var(--PRIMARY_GRAY_DAVYS); // Need Yet another darker gray that is near black, as this is washed out against color
}
.video-placeholder {
	height: 350px;
	width: 100%;
	overflow: hidden;
}
.video-placeholder video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.landing-page-image {
	min-height: 150px;
	max-width: 150px;
}
#landing-content-image-01 {
	flex: 1;
	min-height: 150px;
	max-width: 150px;
	background-image: url('../assets/organized-inbox.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: right;
}
#landing-content-image-02 {
	flex: 1;
	min-height: 150px;
	max-width: 150px;
	background-image: url('../assets/users.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: left;
}
#landing-content-image-03 {
	flex: 1;
	min-height: 150px;
	max-width: 150px;
	background-image: url('../assets/time-savings.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: right;
}
.content-button {
	flex: 1;
	margin-top: 20px;
}
.landing-page-content-button {
	flex: 1;
	background-color: var(--PRIMARY-WHITE);
	border-radius: 5px;
	padding: 0.6rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-in-out;
	cursor: pointer !important;
	border: none;
	font-size: 1.8rem;
	color: var(--PRIMARY-BLUE);
	font-weight: bold;
}
.landing-button:hover {
	box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
	animation: grow 100ms;
	animation-timing-function: ease-in-out;
	transform: scale(1.5);
	cursor: pointer;
}
@keyframes grow {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.5);
	}
}
</style>
