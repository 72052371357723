import { createRouter, createWebHistory } from 'vue-router';
import App from '../App';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			name: 'Home',
			path: '/',
			component: App,
		},
		{
			name: 'Index',
			path: '/',
			component: App,
		},
		{
			name: 'Login',
			path: '/login',
			component: App,
		},
		{
			name: 'Loading',
			path: '/loading',
			component: App,
		},
		{
			name: 'Acceptance',
			path: '/acceptance',
			component: App,
		},
		{
			name: 'Tools',
			path: '/tools',
			component: App,
		},
	],
});
export default router;
