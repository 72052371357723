<template>
	<div
		@mouseover="setActiveTool(toolType)"
		@mouseleave="setActiveTool(null)"
		:id="getToolContainerId"
		class="tool-container">
		<tool-bar :toolType="toolType"></tool-bar>
		<div v-if="toolType === 'label'" :class="'viewer '.concat(toolViewLabel).concat('-view')">
			<tool-data-label></tool-data-label>
		</div>
		<div v-if="toolType === 'filter'" :class="'viewer '.concat(toolViewFilter).concat('-view')">
			<tool-data-filter></tool-data-filter>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import * as actions from '../store/actions/actions';
import toolWrapperConf from '@/store/maps/toolWrapperConf';
import ToolBar from './ToolBar';
import ToolDataFilter from './ToolDataFilter';
import ToolDataLabel from './ToolDataLabel';
export default {
	name: 'ToolWrapper',
	components: {
		ToolBar,
		ToolDataFilter,
		ToolDataLabel,
	},
	emits: ['setActiveLabel', 'setActiveFilter'],
	props: {
		toolType: String,
	},
	data() {
		return {
			tool: null,
		};
	},
	computed: {
		...mapState(toolWrapperConf.config),
		getToolContainerId() {
			return `${this.toolType}-tool-container`;
		},
	},
	methods: {
		...mapActions({
			setAcceptance: actions.SET_ACCEPTANCE,
			setActiveFilter: actions.SET_ACTIVE_FILTER,
			setActiveLabel: actions.SET_ACTIVE_LABEL,
			setActiveTool: actions.SET_ACTIVE_TOOL,
		}),
	},
};
</script>

<style lang="less">
.tool-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
	margin: 16px;
	border-radius: 16px;
	border: var(--PRIMARY_BORDER);
}
.viewer {
	overflow-x: hidden;
	overflow-y: scroll;
	width: 100%;
	max-width: 100% !important;
	padding: 8px;
	background-color: var(--PRIMARY_WHITE);
}
.view-type-override > {
	display: flex !important;
	flex-direction: column !important;
}
</style>
