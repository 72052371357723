// APP
import App from './App.vue';
import { createApp } from 'vue';
import store from './store/store';
import Router from './router/router';
import VueGtag from 'vue-gtag';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* import specific icons */
import {
	faAt,
	faCircleCheck,
	faCircleXmark,
	faCopy,
	faFilter,
	faHandHoldingDollar,
	faHashtag,
	faLevelDown,
	faPlus,
	faTableCells,
	faTableList,
	faTag,
	faTags,
	faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
/* add icons to the library */
library.add(
	faAt,
	faCircleCheck,
	faCircleXmark,
	faCopy,
	faFilter,
	faHashtag,
	faHandHoldingDollar,
	faLevelDown,
	faPlus,
	faTableCells,
	faTableList,
	faTag,
	faTags,
	faTrashCan,
);
// CREATE APP
createApp(App)
	/* use the Store for VueX */
	.use(store)
	/* use the Router for VueRouter */
	.use(Router)
	/* use the VueGtag for Google Analytics */
	.use(VueGtag, {
		/* set the includes array[0] id object */
		includes: [{ id: 'G-159YSSP1GY' }],
		/* set the tracking params */
		config: {
			/* set the tracking ID */
			id: 'G-6CYCJ6X689',
			/* set the params */
			params: {
				/* set the send_page_view to true */
				send_page_view: true,
			},
		},
	})
	/* add the fontawesome icon component to our app */
	.component('font-awesome-icon', FontAwesomeIcon)
	/* mount the app */
	.mount('#app');
