import { mapState } from 'vuex';

export default {
	config: mapState([
		// APP
		'acceptance', // Used to track if the user has accepted the terms
		'activeFilter', // Active filter data object taken from this.filters
		'activeLabel', // Active filter data object taken from this.labels
		'activeTool', // Active filter/label tool
		'animeCoords', // Used to store the start/end coordinates of the anime element
		'appStage', // Used to track the stage of the app
		'appState', // Used to track the state of the app
		'devMode',
		'dragToggle', // Used to toggle the drag functionality
		'error', // Toggle error
		'errorMessage', // Initialized with empty string
		'filterApprove', // Used to track if the user is approving a filter
		'filterDelete', // Used to track if the user is deleting a filter
		'filterNew', // Used to track if the user is creating a new filter
		'filters', // User's filters retrieved from GCP after Oauth
		'filterIndexedArray', // Array of indexed objects for filter criteria-from dropdown
		'filtersLoaded', // True/False
		'headerMessage', // Message displayed in header element
		'labelApprove', // Used to track if the user is creating a new label
		'labelDelete', // Used to track if the user is deleting a label
		'labelNameArray', // Array of labels names for typeahead
		'labelIdArray', // Array of label IDs for typeahead
		'labelPathArray', // Array of label paths for typeahead
		'labelNew', // Used to track if the user is creating a new label
		'labelUpdate', // Used to track if the user is creating a new label
		'labels', // User's labels retrieved from GCP after Oauth
		'labelsLoaded', // True/False
		'loadingIndicator', // Toggle loading indicator
		'loadingMessage', // Message displayed in loading element
		'messagesObj', // Array of messages for display
		'messageTracker', // Used to track the message index
		'oauthAccessToken', // Used to store the access token after Oauth
		'redirect', // Tracker used to identify if the user is being redirected back from GCP
		'redirectCountDown', // Count to display
		'redirectTimer', // Interval storage
		'toolView', // Used to track the view of the tool - list/cells
		'toolViewLabel', // Used to track the view of the label tool - list/cells
		'toolViewFilter', // Used to track the view of the filter tool - list/cells
		'userDataLoaded', // True once loaded
		'userEmail', // User email retrieved from GCP after Oauth
		'userEmailLoaded', // True once loaded
		'windowLocationHash', // location hash, affected by current oauth flow
		'windowLocationHashExists', // Used to track if the window location hash exists
		// ENDPOINTS
		'endpointBaseUrl', // Base URL for all endpoints label/filter related
		'endpointFilters',
		'endpointLabels',
		'endpointStripeCheckout', // GET
		'endpointUserInfo', // GET
	]),
};
