<template>
	<!-- NEW FILTER -->
	<div v-show="filterNew" class="filter-new">
		<!-- FILTER NOTIFICATION CONTAINER -->
		<div
			v-show="filterNotificationToggle(this.filters.length)"
			:id="`filter-notification-container-${this.filters.length}`"
			:key="this.filters.length"
			class="filter-notification-container">
			<h3>{{ filterNotificationMessage }}</h3>
		</div>
		<!-- FILTER LOADING INDICATOR CONTAINER -->
		<div
			v-show="filterLoadingNew && filterNew"
			:id="`filter-loading-container-${this.filters.length}`"
			:key="this.filters.length"
			class="filter-loading-container">
			<div class="filter-loading-indicator">
				<span class="filter-loader__element"></span>
				<span class="filter-loader__element"></span>
				<span class="filter-loader__element"></span>
				<span class="filter-loader__element"></span>
			</div>
		</div>
		<!-- NEW FILTER CONTAINER -->
		<div
			v-if="!filterLoadingNew && !filterNotification && filterNew"
			@drop.prevent="onDrop(event, filters.length - 1)"
			class="filter-data-wrapper"
			@mouseover="setActiveFilter(696969)">
			<!-- TOOL ITEM EDITOR-->
			<tool-item-editor
				:idx="filters.length - 1"
				:key="filters.length - 1"
				:override="true"
				tool="filter"></tool-item-editor>
			<!-- ID -->
			<div class="filter-data-container">
				<div :class="`filter-icon-wrapper filter-button ${divClassDisabled}`">
					<font-awesome-icon
						icon="fa-solid fa-hashtag"
						id="filter-button-hashtag"
						:class="['filter-button-icon', iconClassDisabled]"
						size="2x" />
				</div>
				<div class="filter-id-wrapper"></div>
			</div>
			<!-- Label IDs -->
			<div class="filter-data-container">
				<div :class="`filter-icon-wrapper filter-button ${divClassDisabled}`">
					<font-awesome-icon
						icon="fa-solid fa-tags"
						id="filter-button-tags"
						:class="['filter-button-icon', iconClassDisabled]"
						size="2x" />
				</div>
				<div class="filter-label-ids-wrapper"></div>
			</div>
			<!-- FILTER FROM -->
			<div class="filter-data-container">
				<div :class="`filter-icon-wrapper filter-button ${divClassEnabled}`">
					<div :class="`filter-icon-wrapper filter-button ${divClassEnabled}`">
						<font-awesome-icon
							icon="fa-solid fa-at"
							id="filter-button-at"
							:class="['filter-button-icon', iconClassEnabled]"
							size="2x" />
					</div>
				</div>
				<!-- INPUT -->
				<div class="filter-criteria-from-wrapper">
					<div class="filter-criteria-from-heading"><h6>From:</h6></div>
					<div class="filter-criteria-from-input-container">
						<input
							v-model="filterNewInputValue"
							@input.stop="handleInput($event, filters.length - 1)"
							:minlength="3"
							:maxlength="30"
							:placeholder="filters[filters.length - 1].original.criteria.from"
							class="new-filter-from-input"
							id="new-filter-from-input"
							type="text" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- EXISTING FILTERS -->
	<div
		v-for="(filter, idx) in filters"
		v-show="idx !== filters.length - 1"
		@drop.prevent="onDrop(event, idx)"
		@mouseover="setActiveFilter(idx)"
		:key="idx"
		class="filter">
		<!-- FILTER NOTIFICATION CONTAINER -->
		<div
			v-show="filterNotificationToggle(idx)"
			:id="`filter-notification-container-${idx}`"
			:key="idx"
			class="filter-notification-container"></div>
		<!-- FILTER LOADING INDICATOR CONTAINER -->
		<div
			v-show="filterExistingLoadingToggle(idx)"
			:id="`filter-loading-container-${idx}`"
			:key="idx"
			class="filter-loading-container">
			<div class="filter-loading-indicator">
				<span class="filter-loader__element"></span>
				<span class="filter-loader__element"></span>
				<span class="filter-loader__element"></span>
				<span class="filter-loader__element"></span>
			</div>
		</div>
		<!-- FILTER CONTAINER -->
		<div
			v-show="filterDataToggle(idx)"
			@dragenter.prevent="setActive"
			@dragover.prevent="setActive"
			@dragleave.prevent="setInactive"
			@drop.prevent="onDrop(event, idx)"
			@focus="setActiveFilter(idx)"
			@mouseover="setActiveFilter(idx)"
			:data-active="active"
			:key="idx"
			:id="`filter-${idx}`"
			class="filter-data-wrapper">
			<!-- TOOL ITEM EDITOR-->
			<tool-item-editor :idx="idx" :key="idx" tool="filter"></tool-item-editor>
			<!-- ID -->
			<div class="filter-data-container">
				<div :class="`filter-icon-wrapper filter-button ${divClassDisabled}`">
					<font-awesome-icon
						icon="fa-solid fa-hashtag"
						id="filter-button-hashtag"
						:class="['filter-button-icon', iconClassDisabled]"
						size="2x" />
				</div>
				<div class="filter-id-wrapper"></div>
			</div>
			<!-- Label IDs -->
			<div class="filter-data-container">
				<div :class="`filter-icon-wrapper filter-button ${divClassDisabled}`">
					<font-awesome-icon
						icon="fa-solid fa-tags"
						id="filter-button-tags"
						:class="['filter-button-icon', iconClassDisabled]"
						size="2x" />
				</div>
				<div class="filter-label-ids-wrapper"></div>
			</div>
			<!--CRITERIA FROM DROPDOWN -->
			<div class="filter-data-container">
				<div :class="`filter-icon-wrapper filter-button ${divClassEnabled}`">
					<div :class="`filter-icon-wrapper filter-button ${divClassEnabled}`">
						<font-awesome-icon
							icon="fa-solid fa-at"
							id="filter-button-at"
							:class="['filter-button-icon', iconClassEnabled]"
							size="2x" />
					</div>
				</div>
				<div class="filter-criteria-from-wrapper">
					<!-- ICON -->
					<div class="filter-criteria-from-heading"><h6>From:</h6></div>
					<div class="filter-criteria-from-dropdown-container">
						<dropdown-component :idx="idx" :key="idx"></dropdown-component>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import { mapActions, mapState } from 'vuex';
import * as actions from '../store/actions/actions';
import toolDataFilterConf from '../store/maps/toolDataFilterConf';
import DropdownComponent from './DropdownComponent.vue';
import ToolItemEditor from './ToolItemEditor.vue';
export default {
	name: 'ToolDataFilter',
	components: { DropdownComponent, ToolItemEditor },
	emits: ['setActiveFilter'],
	data() {
		return {
			active: ref(false), // Drag and drop
			events: ['dragenter', 'dragover', 'dragleave', 'drop'],
			filterNotificationMessage: 'Please drag a label to the filter before continuing',
			inActiveTimeout: null, // Drag and drop
			localValue: this.value, // Local value for input
			divClassEnabled: 'filter-button-enabled',
			divClassDisabled: 'filter-button-disabled',
			iconClassEnabled: 'filter-button-icon-enabled',
			iconClassDisabled: 'filter-button-icon-disabled',
		};
	},
	computed: {
		...mapState(toolDataFilterConf.config),
	},
	mounted() {
		this.events.forEach((eventName) => {
			document.body.addEventListener(eventName, this.preventDefaults);
		});
	},
	unmounted() {
		this.events.forEach((eventName) => {
			document.body.removeEventListener(eventName, this.preventDefaults);
		});
	},
	methods: {
		...mapActions({
			setActiveFilter: actions.SET_ACTIVE_FILTER,
			setActiveLabel: actions.SET_ACTIVE_LABEL,
			setActiveTool: actions.SET_ACTIVE_TOOL,
			setEditorButtonsEnabled: actions.SET_EDITOR_BUTTONS_ENABLED,
			setFilterDelete: actions.SET_FILTER_DELETE,
			setFilterExtraCrispyCriteriaFrom: actions.SET_FILTER_EXTRA_CRISPY_CRITERIA_FROM,
			setFilterExtraCrispyLabelIds: actions.SET_FILTER_EXTRA_CRISPY_LABEL_IDS,
			setFilterNewInputValue: actions.SET_FILTER_NEW_INPUT_VALUE,
			setFilterToggle: actions.SET_FILTER_TOGGLE,
			setToolView: actions.SET_TOOL_VIEW,
		}),
		filterDataToggle(idx) {
			const loaderHidden = this.filterLoadingExisting === idx && this.filterToggle;
			const notificationHidden = this.filterNotification === idx;
			const filterDataHide = loaderHidden === false && notificationHidden === false;
			return filterDataHide;
		},
		filterExistingLoadingToggle(idx) {
			return this.filterLoadingExisting === idx && this.filterToggle;
		},
		getFilterCriteriaFromElipsized(filterCriteriaFrom) {
			if (filterCriteriaFrom) {
				return filterCriteriaFrom.slice(0, 29).concat('...');
			}
		},
		getFilterIdElipsized(filterId) {
			if (filterId) {
				return filterId.slice(0, 29).concat('...');
			}
		},
		filterNewLoadingToggle(idx) {
			return this.filterNew;
		},
		filterNotificationToggle(idx) {
			return this.filterNotification === idx;
		},
		handleInput(event, idx) {
			this.setFilterNewInputValue(event.target.value);
			const storeUpdate = { criteria: { from: this.filterNewInputValue }, idx: idx };
			this.setFilterExtraCrispyCriteriaFrom(storeUpdate);
		},
		onDrop(event, idx) {
			this.setInactive(event);
			this.setActiveFilter(idx);
			const labelId = this.activeLabel.original.id;
			const filterIdx = idx;
			this.setFilterExtraCrispyLabelIds({ labelId: labelId, filterIdx: filterIdx });
		},
		parseFilterLabelIds(filterAction) {
			/**
			 * Not actual label IDs, a space delimited string
			 * of label names.
			 *
			 * Prevents undefined error
			 *
			 * Examples:
			 * labelPath/label1 label2
			 *
			 * or
			 *
			 * labelPath/label1-p1 label1-p2 label2/or/labelPath/label2
			 */
			return filterAction === undefined ? null : filterAction.addLabelIds[0];
		},
		preventDefaults(e) {
			e.preventDefault();
		},
		setActive(idx) {
			/**
			 * set activeFilter,
			 * Update active value,
			 * clearTimeouts
			 */
			this.setActiveFilter(idx);
			this.active = true;
			clearTimeout(this.inActiveTimeout);
		},
		setInactive(idx) {
			/**
			 * Used for setting drag inactive properties,
			 * prevents the dragleave event from flickering when fired,
			 * 50ms delay recommended, but untested,
			 * waits for the next dragenter event timeout to clear,
			 * sets it back to active.
			 */
			this.setActiveFilter(idx);
			this.inActiveTimeout = setTimeout(() => {
				this.active = false;
			}, 50);
		},
	},
};
</script>

<style lang="less">
/** FILTER */
.filter {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	margin: 8px 4px;
	width: 100%;
	padding: 2px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 2px 2px 2px var(--PRIMARY_GRAY);
}
.filter:hover {
	background-color: var(--PRIMARY_WHITE);
	box-shadow: 2px 2px 2px var(--PRIMARY_BLUE);
}
.filter-notification-container {
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: center;
	align-content: center;
	justify-content: center;
	top: 0;
	left: 0;
	margin: 0;
	padding: 4px;
	height: 100%;
	min-height: 24px;
	width: inherit;
	z-index: 1;
	background-color: var(--PRIMARY_YELLOW);
	border: 1px solid var(--PRIMARY_YELLOW);
	border-radius: 4px;
}
.filter-loading-container {
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: center;
	align-content: center;
	justify-content: center;
	top: 0;
	left: 0;
	margin: 0;
	height: 100%;
	width: inherit;
	background-color: var(--PRIMARY_BLUE);
	border: 1px solid var(--PRIMARY_BLUE);
	border-radius: 4px;
	z-index: 1;
}
.filters-container {
	border: 1px solid var(--PRIMARY_WHITE);
	background-color: var(--PRIMARY_WHITE);
}
.filter-data-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	padding-bottom: 1px;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.filter-data-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	width: 100%;
	padding: 2px;
}
.filter-data-wrapper > div:nth-child(2) {
	margin-left: 2px;
}
.filter-data-wrapper > div:nth-child(3) {
	margin-left: 2px;
}
.filter-data-wrapper > div:nth-child(4) {
	justify-content: flex-start;
	width: inherit;
	overflow: hidden;
	margin-left: 2px;
}
// ICONS
.filter-icon-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
}
.filter-button-icon {
	width: 16px;
	height: 16px;
	margin: 1px;
}
.filter-icon {
	margin: 4px 4px 0 0;
	cursor: pointer;
}
// HASH
.filter-button-enabled > .fa-hashtag {
	color: var(--PRIMARY_GREEN);
}
.filter-button-enabled > .fa-hashtag:hover {
	color: var(--PRIMARY_YELLOW);
}
.filter-button-disabled > .fa-hashtag {
	color: var(--PRIMARY_GRAY) !important;
	cursor: not-allowed !important;
}
// TAGS
.filter-button-enabled > .fa-tags {
	color: var(--PRIMARY_GREEN);
}
.filter-button-enabled > .fa-tags:hover {
	color: var(--PRIMARY_YELLOW);
}
.filter-button-disabled > .fa-tags {
	color: var(--PRIMARY_GRAY) !important;
	cursor: not-allowed !important;
}
// AT
.filter-button-enabled > .fa-at {
	color: var(--PRIMARY_BLUE);
}
.filter-button-enabled > .fa-at:hover {
	color: var(--PRIMARY_GREEN);
}
.filter-button-disabled > .fa-at {
	color: var(--PRIMARY_GRAY) !important;
	cursor: not-allowed !important;
}
// FILTER CRITERIA FROM DROPDOWN
.filter-criteria-from-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	width: inherit;
}
.filter-criteria-from-heading {
	flex: 1;
	margin: 0 4px 0 4px;
	max-width: 32px;
}
.filter-criteria-from-heading > h6 {
	font-weight: 600;
}
.filter-criteria-from-dropdown-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 4px;
	width: 100%;
	height: 100%;
	padding-left: 4px;
	border-left: var(--PRIMARY_BORDER);
}
// FILTER LOADER
.filter-loading-indicator {
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: center;
	align-content: center;
	justify-content: center;
	top: 0;
	left: 0;
	margin: 0;
	height: 100%;
	width: inherit;
	background-color: var(--PRIMARY_BLUE);
	border: 1px solid var(--PRIMARY_BLUE);
	border-radius: 4px;
	z-index: 1;
}
.filter-loader__element {
	border-radius: 100%;
	margin: calc(var(--PRIMARY_LOADING_INDICATOR_DOT_SIZE) * 2);
}
.filter-loader__element:nth-child(1) {
	border: var(--PRIMARY_LOADING_INDICATOR_DOT_SIZE) solid var(--PRIMARY_WHITE);
	animation: preloader 0.6s ease-in-out alternate infinite;
}
.filter-loader__element:nth-child(2) {
	border: var(--PRIMARY_LOADING_INDICATOR_DOT_SIZE) solid var(--PRIMARY_GREEN);
	animation: preloader 0.6s ease-in-out alternate 0.2s infinite;
}
.filter-loader__element:nth-child(3) {
	border: var(--PRIMARY_LOADING_INDICATOR_DOT_SIZE) solid var(--PRIMARY_YELLOW);
	animation: preloader 0.6s ease-in-out alternate 0.4s infinite;
}
.filter-loader__element:nth-child(4) {
	border: var(--PRIMARY_LOADING_INDICATOR_DOT_SIZE) solid var(--PRIMARY_RED);
	animation: preloader 0.6s ease-in-out alternate 0.6s infinite;
}
@keyframes preloader {
	100% {
		transform: scale(2);
	}
}
/** NEW FILTER */
.filter-new {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	margin: 8px 4px;
	width: 100%;
	padding: 2px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 2px 2px 2px var(--PRIMARY_BLUE);
}
.new-filter-from-input {
	border: none;
	width: 100%;
	height: 100%;
	padding: 0;
	text-align: center;
}
.filter-criteria-from-input-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 4px;
	width: 100%;
	height: 100%;
	padding-left: 4px;
	border-left: var(--PRIMARY_BORDER);
}
</style>
