import { mapState } from 'vuex';

export default {
	config: mapState([
		// APP
		'activeFilter', // Active filter data object taken from this.filters
		'activeLabel', // Active filter data object taken from this.labels
		'error', // Toggle error
		'errorMessage', // Initialized with empty string
		'filterApprove', // Used to track if the user is approving a filter
		'filterDelete', // Used to track if the user is deleting a filter
		'filterLoadingExisting', // Used to track if the user is creating a new filter
		'filterLoadingNew', // Used to track if the user is creating a new filter
		'filterNew', // Used to track if the user is creating a new filter
		'filterNewInputValue', // Used to track if the user is creating a new filter
		'filterNotification', // Used to hide the filter notification
		'filterToggle', // Used to toggle the filter tool
		'filters', // User's filters retrieved from GCP after Oauth
		'filterIndexedArray', // Array of indexed objects for filter criteria-from dropdown
		'toolViewFilter', // Used to track the view of the filter tool - list/cells
	]),
};
