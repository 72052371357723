import { mapState } from 'vuex';

export default {
	config: mapState([
		'activeLabel', // Active filter data object taken from this.labels
		'activeTool', // Active filter/label tool
		'error', // Toggle error
		'errorMessage', // Initialized with empty string
		'filterNew', // Used to track if the user is creating a new filter
		'filters', // User's filters retrieved from GCP after Oauth
		'labelLoadingExisting', // Used to track if the user is creating a new label
		'labelLoadingNew', // Used to track if the user is creating a new label
		'labelNew', // Used to track if the user is creating a new label
		'labelNotification', // Used to hide the filter notification
		'labelToggle', // Used to toggle the filter tool
		'labels', // User's labels retrieved from GCP after Oauth
		'toolViewLabel', // Used to track the view of the label tool - list/cells
		'toolViewFilter', // Used to track the view of the filter tool - list/cells
	]),
};
