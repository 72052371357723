export const GET_LABEL_ORIGINAL = 'set:label:original';
export const GET_LABEL_EXTRA_CRISPY = 'set:label:extra:crispy';
export const SET_ACCEPTANCE = 'set:acceptance';
export const SET_ACTIVE_FILTER = 'set:active:filter';
export const SET_ACTIVE_LABEL = 'set:active:label';
export const SET_ACTIVE_TOOL = 'set:active:tool';
export const SET_ANIME_DIR_COORDS = 'set:anime:dir:coords';
export const SET_APP_STAGE = 'set:app:stage';
export const SET_APP_STATE = 'set:app:state';
export const SET_DEV_MODE = 'set:dev:mode';
export const SET_DRAG_TOGGLE = 'set:drag:toggle';
export const SET_DROPDOWN_HIDE = 'set:dropdown:hide';
export const SET_EDITOR_BUTTONS_ENABLED = 'set:editor:buttons:enabled';
export const SET_FILTER_APPROVE = 'set:filter:approve';
export const SET_FILTER_CANCEL = 'set:filter:cancel';
export const SET_FILTER_CREATE = 'set:filter:create';
export const SET_FILTER_DELETE = 'set:filter:delete';
export const SET_FILTER_LOADING_EXISTING = 'set:filter:loading:existing';
export const SET_FILTER_LOADING_NEW = 'set:filter:loading:new';
export const SET_FILTER_EXTRA_CRISPY_CRITERIA_FROM = 'set:filter:extra:crispy:criteria:from';
export const SET_FILTER_EXTRA_CRISPY_LABEL_IDS = 'set:filter:extra:crispy:label:ids';
export const SET_FILTER_NEW = 'set:filter:new';
export const SET_FILTER_NEW_INPUT_VALUE = 'set:filter:new:input:value';
export const SET_FILTER_NOTIFICATION = 'set:filter:notification';
export const SET_FILTER_ORIGINAL_CRITERIA_FROM = 'set:filter:original:criteria:from';
export const SET_FILTER_REMOVE = 'set:filter:remove';
export const SET_FILTER_REPLACE = 'set:filter:replace';
export const SET_FILTER_TOGGLE = 'set:filter:toggle';
export const SET_FILTERS = 'set:filters';
export const SET_FILTERS_LOADED = 'set:filters:loaded';
export const SET_HEADER_MESSAGE = 'set:header:message';
export const SET_LABEL_APPROVE = 'set:label:approve';
export const SET_LABEL_CANCEL = 'set:label:cancel';
export const SET_LABEL_CREATE = 'set:label:create';
export const SET_LABEL_DELETE = 'set:label:delete';
export const SET_LABEL_EXTRA_CRISPY = 'set:label:extra:crispy';
export const SET_LABEL_ID_ARRAY = 'set:label:id:array';
export const SET_LABEL_ID_ARRAY_ITEM = 'set:label:id:array:item';
export const SET_LABEL_LOADING_EXISTING = 'set:label:loading:existing';
export const SET_LABEL_LOADING_NEW = 'set:label:loading:new';
export const SET_LABEL_NAME_ARRAY = 'set:label:name:array';
export const SET_LABEL_NAME_ARRAY_ITEM = 'set:label:name:array:item';
export const SET_LABEL_NEW = 'set:label:new';
export const SET_LABEL_NEW_INPUT_RESET = 'set:label:new:input:reset';
export const SET_LABEL_NEW_UPDATE = 'set:label:new:update';
export const SET_LABEL_PATH_ARRAY = 'set:label:path:array';
export const SET_LABEL_PATH_ARRAY_ITEM = 'set:label:path:array:item';
export const SET_LABEL_REMOVE = 'set:label:remove';
export const SET_LABEL_REPLACE = 'set:label:replace';
export const SET_LABEL_TOGGLE = 'set:label:toggle';
export const SET_LABEL_UPDATE = 'set:label:update';
export const SET_LABELS = 'set:labels';
export const SET_LABELS_LOADED = 'set:labels:loaded';
export const SET_LOADING_MESSAGE = 'set:loading:message';
export const SET_LOCAL_STORAGE = 'set:local:storage';
export const SET_MESSAGE_TRACKER = 'set:message:tracker';
export const SET_OAUTH_ACCESS_TOKEN = 'set:oauth:access:token';
export const SET_TOOL_VIEW = 'set:tool:view';
export const SET_TOOL_VIEW_LABEL = 'set:tool:view:label';
export const SET_TOOL_VIEW_FILTER = 'set:tool:view:filter';
export const SET_TYPEAHEAD_PRELOAD = 'set:typeahead:preload';
export const SET_TYPEAHEAD_SUGGESTIONS = 'set:typeahead:suggestions';
export const SET_USER_DATA_LOADED = 'set:user:data:loaded';
export const SET_USER_EMAIL = 'set:user:email';
export const SET_USER_EMAIL_LOADED = 'set:user:email:loaded';
