<template>
	<!-- NEW LABEL -->
	<div v-show="labelNew" class="label-new">
		<!-- LABEL NOTIFICATION CONTAINER -->
		<div
			v-show="labelNotificationToggle(labels.length - 1)"
			:id="`label-notification-container-${labels.length - 1}`"
			:key="labels.length - 1"
			class="label-notification-container"></div>
		<!-- LABEL LOADING INDICATOR CONTAINER -->
		<div
			v-show="labelLoadingNew && labelNew"
			:id="`label-loading-container-${labels.length - 1}`"
			:key="labels.length - 1"
			class="label-loading-container">
			<div class="label-loading-indicator">
				<span class="label-loader__element"></span>
				<span class="label-loader__element"></span>
				<span class="label-loader__element"></span>
				<span class="label-loader__element"></span>
			</div>
		</div>
		<!-- NEW LABEL CONTAINER -->
		<div v-if="!labelLoadingNew && labelNew" class="label-data-wrapper" @mouseover="setActiveLabel(696969)">
			<!-- TOOL ITEM EDITOR-->
			<tool-item-editor
				:idx="labels.length - 1"
				:key="labels.length - 1"
				:override="true"
				tool="label"></tool-item-editor>
			<!-- ID -->
			<div class="label-data-container">
				<div :class="`label-icon-wrapper label-button ${divClassDisabled}`">
					<font-awesome-icon
						icon="fa-solid fa-hashtag"
						id="label-button-hashtag"
						:class="['label-button-icon', iconClassDisabled]"
						size="2x" />
				</div>
				<div class="label-id-wrapper"></div>
			</div>
			<!-- PATH -->
			<div class="label-data-container">
				<div :class="`label-icon-wrapper label-button ${divClassDisabled}`">
					<font-awesome-icon
						icon="fa-solid fa-level-down"
						id="label-button-level-down"
						:class="['label-button-icon', iconClassDisabled]"
						size="2x" />
				</div>
				<div class="label-path-wrapper"></div>
			</div>
			<!-- FILTER NAME -->
			<div class="label-data-container">
				<div :class="`label-icon-wrapper label-button ${divClassEnabled}`">
					<div :class="`label-icon-wrapper label-button ${divClassEnabled}`">
						<font-awesome-icon
							icon="fa-solid fa-tag"
							id="label-button-tag"
							:class="['label-button-icon', iconClassEnabled]"
							size="2x" />
					</div>
				</div>
				<!-- TYPEAHEAD INPUT -->
				<div class="label-name-wrapper">
					<div class="label-name"><h6>Name:</h6></div>
					<div class="label-name-input-container">
						<typeahead-input
							@focus="setDragToggle(true, labels.length - 1)"
							@blur="setDragToggle(false, labels.length - 1)"
							:idx="labels.length - 1"
							:minlength="3"
							:maxlength="30"
							:override="true"
							:size="30"
							id="new-label-name-input"
							class="new-label-name-input"
							placeholder="Give this label a name"
							toolType="labels"
							type="text"
							value="Give this label a name" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- EXISTING LABELS -->
	<div
		v-for="(label, idx) in labels"
		v-show="label !== labels[labels.length - 1]"
		@mouseover="setActiveLabel(idx)"
		:key="label.original.id"
		class="label">
		<!-- LABELS NOTIFICATION CONTAINER -->
		<div
			v-show="labelNotificationToggle(idx)"
			:id="`label-notification-container-${idx}`"
			:key="idx"
			class="label-notification-container"></div>
		<!-- LABELS LOADING INDICATOR CONTAINER -->
		<div
			v-show="labelExistingLoadingToggle(idx)"
			:id="`label-loading-container-${idx}`"
			:key="idx"
			class="label-loading-container">
			<div class="label-loading-indicator">
				<span class="label-loader__element"></span>
				<span class="label-loader__element"></span>
				<span class="label-loader__element"></span>
				<span class="label-loader__element"></span>
			</div>
		</div>
		<!-- LABELS CONTAINER -->
		<div
			v-show="labelDataToggle(idx)"
			@end="drag = false"
			@start="drag = true"
			draggable="true"
			:key="idx"
			:id="`label-${idx}`"
			class="label-data-wrapper">
			<!-- TOOL ITEM EDITOR-->
			<tool-item-editor :idx="idx" :key="idx" :override="true" tool="label"></tool-item-editor>
			<!-- ID -->
			<div class="label-data-container">
				<div :class="`label-icon-wrapper label-button ${divClassDisabled}`">
					<font-awesome-icon
						icon="fa-solid fa-hashtag"
						id="label-button-hashtag"
						:class="['label-button-icon', iconClassDisabled]"
						size="2x" />
				</div>
				<div @class="label - id - wrapper"></div>
			</div>
			<!-- PATH -->
			<div class="label-data-container">
				<div :class="`label-icon-wrapper label-button ${divClassDisabled}`">
					<font-awesome-icon
						icon="fa-solid fa-level-down"
						id="label-button-level-down"
						:class="['label-button-icon', iconClassDisabled]"
						size="2x" />
				</div>
				<div class="label-path-wrapper"></div>
			</div>
			<!--NAME -->
			<div class="label-data-container">
				<div :class="`label-icon-wrapper label-button ${divClassEnabled}`">
					<div :class="`label-icon-wrapper label-button ${divClassEnabled}`">
						<font-awesome-icon
							icon="fa-solid fa-tag"
							id="label-button-tag"
							:class="['label-button-icon', iconClassEnabled]"
							size="2x" />
					</div>
				</div>
				<!-- TYPEAHEAD INPUT -->
				<div class="label-name-wrapper">
					<div class="label-name"><h6>Name:</h6></div>
					<div class="label-name-input-container">
						<typeahead-input
							@blur="setDragToggle(false, idx)"
							@focus="setDragToggle(true, idx)"
							:id="`label-name-input-${idx}`"
							:idx="idx"
							:minlength="3"
							:maxlength="30"
							:override="false"
							:placeholder="label.original.name"
							:size="30"
							:value="label.original.name"
							class="label-name-input"
							toolType="labels"
							type="text" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as actions from '../store/actions/actions';
import toolDataLabelConf from '../store/maps/toolDataLabelConf';
import TypeaheadInput from './TypeaheadInput.vue';
import ToolItemEditor from './ToolItemEditor.vue';
export default {
	name: 'ToolDataLabel',
	components: {
		ToolItemEditor,
		TypeaheadInput,
	},
	data() {
		return {
			divClassEnabled: 'label-button-enabled',
			divClassDisabled: 'label-button-disabled',
			drag: false,
			iconClassEnabled: 'label-button-icon-enabled',
			iconClassDisabled: 'label-button-icon-disabled',
			localValue: this.value,
			typeaheadInputClicked: false,
		};
	},
	computed: {
		...mapState(toolDataLabelConf.config),
	},
	methods: {
		...mapActions({
			setActiveLabel: actions.SET_ACTIVE_LABEL,
			setActiveTool: actions.SET_ACTIVE_TOOL,
			setLabelDelete: actions.SET_LABEL_DELETE,
			setDragToggle: actions.SET_DRAG_TOGGLE,
			setToolView: actions.SET_TOOL_VIEW,
		}),
		dragFocusFix() {
			document.getElementById(`label-id-${this.arrayIDX}`).focus;
		},
		labelDataToggle(idx) {
			const loaderHidden = this.labelLoadingExisting === idx && this.labelToggle;
			const notificationHidden = this.labelNotification === idx;
			const labelDataHide = loaderHidden === false && notificationHidden === false;
			return labelDataHide;
		},
		labelExistingLoadingToggle(idx) {
			return this.labelLoadingExisting === idx && this.labelToggle;
		},
		labelNewLoadingToggle(idx) {
			return this.labelNew;
		},
		labelNotificationToggle(idx) {
			return this.labelNotification === idx;
		},
		getLabelName(idx) {
			const label = this.labels[idx].original.name;
			const splitLabelName = label.name.split('/');
			return splitLabelName[splitLabelName.length - 1];
		},
		setDragToggle() {
			/**
			 * This turns off draggable when the input
			 * is clicked, so that when the input is
			 * clicked out and the label container element
			 * is immediately mousedown upon, the drag
			 * can be reapplied and the side effect of
			 * element selection rather than drag can be
			 * prevented
			 */
			this.setDragToggle(false);
		},
	},
};
</script>

<style lang="less">
/** FILTER */
.label {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	margin: 8px 4px;
	width: 100%;
	padding: 2px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 2px 2px 2px var(--PRIMARY_GRAY);
}
.label:hover {
	background-color: var(--PRIMARY_WHITE);
	box-shadow: 2px 2px 2px var(--PRIMARY_BLUE);
}
.label-notification-container {
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: center;
	align-content: center;
	justify-content: center;
	top: 0;
	left: 0;
	margin: 0;
	height: 100%;
	width: inherit;
	border-radius: 4px;
	z-index: 1;
	border: 1px solid var(--PRIMARY_YELLOW);
	background-color: var(--PRIMARY_YELLOW);
}
.label-loading-container {
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: center;
	align-content: center;
	justify-content: center;
	top: 0;
	left: 0;
	margin: 0;
	height: 100%;
	width: inherit;
	border-radius: 4px;
	z-index: 1;
	border: 1px solid var(--PRIMARY_BLUE);
	background-color: var(--PRIMARY_BLUE);
}
.label-container {
	border: 1px solid var(--PRIMARY_WHITE);
	background-color: var(--PRIMARY_WHITE);
}
.label-data-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	padding-bottom: 1px;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.label-data-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	width: 100%;
	padding: 2px;
}
.label-data-wrapper > div:nth-child(2) {
	margin-left: 2px;
}
.label-data-wrapper > div:nth-child(3) {
	margin-left: 2px;
}
.label-data-wrapper > div:nth-child(4) {
	justify-content: flex-start;
	width: inherit;
	overflow: hidden;
	margin-left: 2px;
}
// ICONS
.label-icon-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
}
.label-button-icon {
	width: 16px;
	height: 16px;
	margin: 1px;
}
.label-icon {
	margin: 4px 4px 0 0;
	cursor: pointer;
}
// HASH
.label-button-enabled > .fa-hashtag {
	color: var(--PRIMARY_GREEN);
}
.label-button-enabled > .fa-hashtag:hover {
	color: var(--PRIMARY_YELLOW);
}
.label-button-disabled > .fa-hashtag {
	color: var(--PRIMARY_GRAY) !important;
	cursor: not-allowed !important;
}
// LEVEL DOWN
.label-button-enabled > .fa-arrow-turn-down {
	color: var(--PRIMARY_GREEN);
}
.label-button-enabled > .fa-arrow-turn-down:hover {
	color: var(--PRIMARY_YELLOW);
}
.label-button-disabled > .fa-arrow-turn-down {
	color: var(--PRIMARY_GRAY) !important;
	cursor: not-allowed !important;
}
// TAG
.label-button-enabled > .fa-tag {
	color: var(--PRIMARY_GREEN);
}
.label-button-enabled > .fa-tag:hover {
	color: var(--PRIMARY_YELLOW);
}
.label-button-disabled > .fa-tag {
	color: var(--PRIMARY_GRAY) !important;
	cursor: not-allowed !important;
}
// FILTER NAME
.label-name-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	width: inherit;
}
.label-name-heading {
	flex: 1;
	margin: 0 4px 0 4px;
	max-width: 32px;
}
.label-name-heading > h6 {
	font-weight: 600;
}
// LABEL LOADER
.label-loading-indicator {
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: center;
	align-content: center;
	justify-content: center;
	top: 0;
	left: 0;
	margin: 0;
	height: 100%;
	width: inherit;
	background-color: var(--PRIMARY_BLUE);
	border: 1px solid var(--PRIMARY_BLUE);
	border-radius: 4px;
	z-index: 1;
}
.label-loader__element {
	border-radius: 100%;
	margin: calc(var(--PRIMARY_LOADING_INDICATOR_DOT_SIZE) * 2);
}
.label-loader__element:nth-child(1) {
	border: var(--PRIMARY_LOADING_INDICATOR_DOT_SIZE) solid var(--PRIMARY_WHITE);
	animation: preloader 0.6s ease-in-out alternate infinite;
}
.label-loader__element:nth-child(2) {
	border: var(--PRIMARY_LOADING_INDICATOR_DOT_SIZE) solid var(--PRIMARY_GREEN);
	animation: preloader 0.6s ease-in-out alternate 0.2s infinite;
}
.label-loader__element:nth-child(3) {
	border: var(--PRIMARY_LOADING_INDICATOR_DOT_SIZE) solid var(--PRIMARY_YELLOW);
	animation: preloader 0.6s ease-in-out alternate 0.4s infinite;
}
.label-loader__element:nth-child(4) {
	border: var(--PRIMARY_LOADING_INDICATOR_DOT_SIZE) solid var(--PRIMARY_RED);
	animation: preloader 0.6s ease-in-out alternate 0.6s infinite;
}
@keyframes preloader {
	100% {
		transform: scale(2);
	}
}
/** NEW LABEL */
.label-new {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	margin: 8px 4px;
	width: 100%;
	padding: 2px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 2px 2px 2px var(--PRIMARY_BLUE);
}
.new-label-name-input {
	border: none;
	width: 100%;
	height: 100%;
	padding: 0;
	text-align: center;
}
.label-name-input-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 4px;
	width: 100%;
	height: 100%;
	padding-left: 4px;
	border-left: var(--PRIMARY_BORDER);
}
</style>
