<template>
	<div :class="`${tool}-editor`">
		<!-- <div :id="`${tool}-editor-button-checkbox`" :class="`${tool}-editor-checkbox`"></div> -->
		<!-- CANCEL BUTTON -->
		<div :id="`${tool}-editor-button-cancel-${idx}`" :class="`${tool}-editor-button`">
			<div @click.stop="handleCancel(idx)" :id="`${tool}-editor-button-c-${idx}`" :class="divClass">
				<font-awesome-icon
					@click.stop="handleCancel(idx)"
					:class="iconClass"
					icon="fa-solid fa-circle-xmark"
					id="tool-editor-icon-xmark" />
			</div>
		</div>
		<!-- APPROVE BUTTON -->
		<div :id="`${tool}-editor-button-approve-${idx}`" :class="`${tool}-editor-button`">
			<div :id="`${tool}-editor-button-a-${idx}`" :class="divClass" @click.stop="handleApprove(idx)">
				<font-awesome-icon :class="iconClass" icon="fa-solid fa-circle-check" id="tool-editor-icon-check" />
			</div>
		</div>
		<!-- DELETE BUTTON -->
		<div :id="`${tool}-editor-button-delete`" :class="(`${tool}-editor-button`, `${tool}-editor-button-delete`)">
			<div @click.stop="handleDelete(idx)" :id="`${tool}-editor-button-d-${idx}`" :class="divClassEnabled">
				<font-awesome-icon
					@click.stop="handleDelete(idx)"
					:class="`${tool}-editor-button-icon`"
					icon="fa-solid fa-trash-can"
					id="tool-editor-icon-trash"
					size="2x" />
			</div>
		</div>
	</div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import * as actions from '../store/actions/actions';
import toolItemEditorConf from '../store/maps/toolItemEditorConf';
var _ = require('lodash');
// TODO: Fix the reverse logic in the editor buttons for labels, they shouldn't need it but they are
export default {
	name: 'ToolItemEditor',
	components: {},
	props: {
		idx: {
			// Passed prop for UI state
			type: Number,
			required: true,
			default: 0,
		},
		tool: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			divClassEnabled: `${this.tool}-editor-button-enabled`,
			divClassDisabled: `${this.tool}-editor-button-disabled`,
			iconClassEnabled: `${this.tool}-editor-button-icon-enabled`,
			iconClassDisabled: `${this.tool}-editor-button-icon-disabled`,
		};
	},
	computed: {
		...mapState(toolItemEditorConf.config),
		divClass() {
			if (this.tool === 'label') {
				return this.labels[this.idx].extraCrispy.editorButtonsEnabled
					? this.divClassEnabled
					: this.divClassDisabled;
			} else if (this.tool === 'filter') {
				return this.filters[this.idx].extraCrispy.editorButtonsEnabled
					? this.divClassEnabled
					: this.divClassDisabled;
			} else {
				return this.divClassDisabled;
			}
		},
		iconClass() {
			if (this.tool === 'label') {
				return this.labels[this.idx].extraCrispy.editorButtonsEnabled
					? `${this.tool}-editor-button-icon ${this.iconClassEnabled}`
					: `${this.tool}-editor-button-icon ${this.iconClassDisabled}`;
			} else if (this.tool === 'filter') {
				return this.filters[this.idx].extraCrispy.editorButtonsEnabled
					? `${this.tool}-editor-button-icon ${this.iconClassEnabled}`
					: `${this.tool}-editor-button-icon ${this.iconClassDisabled}`;
			} else {
				return this.iconClassDisabled;
			}
		},
	},
	methods: {
		...mapActions({
			setDropdownHide: actions.SET_DROPDOWN_HIDE,
			setFilterApprove: actions.SET_FILTER_APPROVE,
			setFilterCancel: actions.SET_FILTER_CANCEL,
			setFilterCreate: actions.SET_FILTER_CREATE,
			setFilterExtraCrispyCriteriaFrom: actions.SET_FILTER_EXTRA_CRISPY_CRITERIA_FROM,
			setFilterDelete: actions.SET_FILTER_DELETE,
			setFilterNew: actions.SET_FILTER_NEW,
			setFilterNewInputValue: actions.SET_FILTER_NEW_INPUT_VALUE,
			setFilterLoadingNew: actions.SET_FILTER_LOADING_NEW,
			setFilterLoadingExisting: actions.SET_FILTER_LOADING_EXISTING,
			setFilterNotification: actions.SET_FILTER_NOTIFICATION,
			setFilterOriginalCriteriaFrom: actions.SET_FILTER_ORIGINAL_CRITERIA_FROM,
			setFilterRemove: actions.SET_FILTER_REMOVE,
			setFilterReplace: actions.SET_FILTER_REPLACE,
			setFilterToggle: actions.SET_FILTER_TOGGLE,
			setFilters: actions.SET_FILTERS,
			setLabelApprove: actions.SET_LABEL_APPROVE,
			setLabelCancel: actions.SET_LABEL_CANCEL,
			setLabelCreate: actions.SET_LABEL_CREATE,
			setLabelDelete: actions.SET_LABEL_DELETE,
			setLabelIdArray: actions.SET_LABEL_ID_ARRAY,
			setLabelIdArrayItem: actions.SET_LABEL_ID_ARRAY_ITEM,
			setLabelLoadingNew: actions.SET_LABEL_LOADING_NEW,
			setLabelLoadingExisting: actions.SET_LABEL_LOADING_EXISTING,
			setLabelNameArray: actions.SET_LABEL_NAME_ARRAY,
			setLabelNameArrayItem: actions.SET_LABEL_NAME_ARRAY_ITEM,
			setLabelNew: actions.SET_LABEL_NEW,
			setLabelNewInputReset: actions.SET_LABEL_NEW_INPUT_RESET,
			setLabelNewUpdate: actions.SET_LABEL_NEW_UPDATE,
			setLabelPathArray: actions.SET_LABEL_PATH_ARRAY,
			setLabelPathArrayItem: actions.SET_LABEL_PATH_ARRAY_ITEM,
			setLabelRemove: actions.SET_LABEL_REMOVE,
			setLabelReplace: actions.SET_LABEL_REPLACE,
			setLabelToggle: actions.SET_LABEL_TOGGLE,
			setLabelUpdate: actions.SET_LABEL_UPDATE,
			setLabels: actions.SET_LABELS,
			setLocalStorage: actions.SET_LOCAL_STORAGE,
		}),
		async handleApprove(idx) {
			console.debug('handleApprove()');
			// console.debug('idx: ', idx);
			if (this.tool === 'label') {
				console.debug('Approving Label');
				if (idx === this.labels.length - 1) {
					// {
					//     "labelListVisibility": "labelShow/labelShowIfUnread/labelHide",
					//     "messageListVisibility": "show/hide",
					//     "name": "",
					//     "color": {
					//         "backgroundColor": "",
					//         "textColor": ""
					//     },
					//     "id": "",
					//     "messagesTotal": 0,
					//     "messagesUnread": 0,
					//     "threadsTotal": 0,
					//     "threadsUnread": 0,
					//     "type": "system/user"
					// }
					// let labelBlank = {
					// 	original: {
					// 		id: '',
					// 		name: '',
					// 		type: '',
					// 	},
					// 	extraCrispy: {
					// 		editorButtonsEnabled: true,
					// 		cancelUpdate: true,
					// 		placeholder: 'Give your label a name',
					// 		id: '',
					// 		name: '',
					// 		type: '',
					// 	},
					// };
					// console.debug('labelNew');
					// console.debug('idx === this.labels.length - 1: ', idx === this.labels.length - 1);
					// console.debug('this.label[idx].extraCrispy: ', this.labels[idx].extraCrispy);
					this.setLabelNew(true);
					this.setLabelLoadingNew(this.labels.length - 1);
					// console.debug('this.labelNewLoading: ', this.labelNewLoading);
					const labelNewGCP = {
						labelListVisibility: 'labelShow',
						messageListVisibility: 'show',
						name: this.labels[idx].extraCrispy.name,
					};
					// console.debug('labelNewGCP: ', labelNewGCP);
					const labelNewResponse = await this.setLabelCreate(labelNewGCP);
					// console.debug('labelNewResponse: ', labelNewResponse);
					const labelNew = {
						original: {
							id: labelNewResponse.data.id,
							name: labelNewResponse.data.name,
							path: labelNewResponse.data.name,
						},
						extraCrispy: {
							editorButtonsEnabled: false,
							cancelUpdate: false,
							id: labelNewResponse.data.id,
							name: labelNewResponse.data.name,
							path: labelNewResponse.data.name,
						},
					};
					// TODO: Need to run an alphabetical sort on the labels array based on original.name
					// console.debug('labelNew: ', labelNew);
					// console.debug('idx: ', idx);
					// console.debug('this.labels.length: ', this.labels.length);
					await this.setLabelNewUpdate(labelNew);
					// idx === this.labels.length - 1;
					// console.debug('idx: ', idx);
					// console.debug('this.labels.length: ', this.labels.length);
					this.setLabelNew(false);
					this.setLabelLoadingNew(false);
					console.debug('Label Created');
				} else {
					console.debug('labelExisting');
					this.setLabelToggle(true);
					this.setLabelLoadingExisting(idx);
					console.debug('this.labelLoadingExisting: ', this.labelLoadingExisting);
					let labelGCP = {
						id: this.labels[idx].extraCrispy.id,
						labelListVisibility: 'labelShow',
						messageListVisibility: 'show',
						name: this.labels[idx].extraCrispy.name,
						type: 'user',
					};
					console.debug('labelGCP: ', labelGCP);
					const gcpResponse = await this.setLabelUpdate(labelGCP);
					console.debug('response: ', gcpResponse);
					let labelUpdate = {
						original: {
							id: this.labels[idx].extraCrispy.id,
							name: this.labels[idx].extraCrispy.name,
							path: this.labels[idx].extraCrispy.path,
						},
						extraCrispy: {
							editorButtonsEnabled: false,
							cancelUpdate: false,
							id: this.labels[idx].extraCrispy.id,
							name: this.labels[idx].extraCrispy.name,
							path: this.labels[idx].extraCrispy.path,
						},
					};
					this.setLabelReplace({ idx, labelUpdate });
					this.setLabelToggle(false);
					this.setLabelLoadingExisting(false);
				}
			} else if (this.tool === 'filter') {
				console.debug('Approving Filter');
				let filterGCP = {
					action: {
						forward: this.filters[idx].extraCrispy.action.forward || '',
						addLabelIds: this.filters[idx].extraCrispy.action.addLabelIds || [],
						removeLabelIds: this.filters[idx].extraCrispy.action.removeLabelIds || [],
					},
					criteria: {
						from: this.filters[idx].extraCrispy.criteria.from || '',
						excludeChats: this.filters[idx].extraCrispy.criteria.excludeChats || false,
						hasAttachment: this.filters[idx].extraCrispy.criteria.hasAttachment || false,
						negatedQuery: this.filters[idx].extraCrispy.criteria.negatedQuery || '',
						query: this.filters[idx].extraCrispy.criteria.query || '',
						size: this.filters[idx].extraCrispy.criteria.size || 0,
						sizeComparison: this.filters[idx].extraCrispy.criteria.sizeComparison || 'unspecified',
						subject: this.filters[idx].extraCrispy.criteria.subject || '',
						to: this.filters[idx].extraCrispy.criteria.to || '',
					},
					id: '',
				};
				if (idx === this.filters.length - 1) {
					// console.debug('filterNew');
					let filterBlank = {
						original: { action: { addLabelIds: [] }, criteria: { from: '@test.at' }, id: 'Dummy ID' },
						extraCrispy: {
							editorButtonsEnabled: true,
							cancelUpdate: true,
							action: {
								forward: '', //state.filters[idx].extraCrispy.action.forward,
								addLabelIds: [], //state.filters[idx].extraCrispy.action.addLabelIds,
								removeLabelIds: [], //state.filters[idx].extraCrispy.action.removeLabelIds,
							},
							criteria: {
								from: '',
								excludeChats: false, //state.filters[idx].extraCrispy.criteria.excludeChats,
								hasAttachment: false, //state.filters[idx].extraCrispy.criteria.hasAttachment,
								negatedQuery: '', //state.filters[idx].extraCrispy.criteria.negatedQuery,
								query: '', //state.filters[idx].extraCrispy.criteria.query,
								size: 0, //state.filters[idx].extraCrispy.criteria.size,
								sizeComparison: 'unspecified', //state.filters[idx].extraCrispy.criteria.sizeComparison,
								subject: '', //state.filters[idx].extraCrispy.criteria.subject,
								to: '', //state.filters[idx].extraCrispy.criteria.to,
							},
							id: '',
						},
					};
					const filterNew = {
						action: {
							forward: '', //state.filters[idx].extraCrispy.action.forward,
							addLabelIds: this.filters[idx].extraCrispy.action.addLabelIds || [],
							removeLabelIds: [], //state.filters[idx].extraCrispy.action.removeLabelIds,
						},
						criteria: {
							from: this.filters[idx].extraCrispy.criteria.from,
							excludeChats: false, //state.filters[idx].extraCrispy.criteria.excludeChats,
							hasAttachment: false, //state.filters[idx].extraCrispy.criteria.hasAttachment,
							negatedQuery: '', //state.filters[idx].extraCrispy.criteria.negatedQuery,
							query: '', //state.filters[idx].extraCrispy.criteria.query,
							size: 0, //state.filters[idx].extraCrispy.criteria.size,
							sizeComparison: 'unspecified', //state.filters[idx].extraCrispy.criteria.sizeComparison,
							subject: '', //state.filters[idx].extraCrispy.criteria.subject,
							to: '', //state.filters[idx].extraCrispy.criteria.to,
						},
						id: '', //state.filters[idx].extraCrispy.id,
					};
					this.setFilterLoadingNew(this.filters.length - 1);
					// console.debug('this.filterLoadingNew: ', this.filterLoadingNew - 1);
					this.setFilterNew(true);
					// console.debug('this.filterNew: ', this.filterNew);
					if (
						this.filterLoadingNew === idx &&
						this.filters[idx].extraCrispy.action.addLabelIds.length === 0
					) {
						// console.debug('Filter contains no labels');
						this.setFilterLoadingNew(false);
						this.setFilterNotification(idx + 1);
						setTimeout(async () => {
							this.setFilterLoadingNew(false);
							this.setFilterNotification(false);
						}, 3000);
						return;
					} else {
						const filterNewResponse = await this.setFilterCreate(filterNew);
						const data = filterNewResponse.data;
						const id = data.id;
						const criteriaFrom = data.criteria.from;
						const actionAddLabelIds = data.action.addLabelIds;
						const filtersNew = this.filters;
						const filterLast = filtersNew.pop();
						filterLast.original.id = id;
						filterLast.original.criteria.from = criteriaFrom;
						filterLast.original.action.addLabelIds = actionAddLabelIds;
						filterLast.extraCrispy.editorButtonsEnabled = false;
						filterLast.extraCrispy.cancelUpdate = false;
						filterLast.extraCrispy.id = id;
						filterLast.extraCrispy.criteria.from = criteriaFrom;
						filterLast.extraCrispy.action.addLabelIds = actionAddLabelIds;
						filtersNew.unshift(filterLast);
						// TODO: Need to run an alphabetical sort on the filters array based on original.criteria.from
						filtersNew.push(filterBlank);
						this.setFilters(filtersNew);
						this.setFilterLoadingNew(false);
						this.setFilterNew(false);
						console.debug('Filter Created');
					}
				} else {
					// console.debug('filterExisting');
					this.setFilterToggle(true);
					this.setFilterLoadingExisting(idx);
					let filterOriginal = {
						original: {
							action: _.cloneDeep(this.filters[idx].original.action),
							criteria: _.cloneDeep(this.filters[idx].original.criteria),
							id: this.filters[idx].original.id,
						},
						extraCrispy: {
							editorButtonsEnabled: this.filters[idx].extraCrispy.editorButtonsEnabled,
							cancelUpdate: this.filters[idx].extraCrispy.cancelUpdate,
							action: _.cloneDeep(this.filters[idx].extraCrispy.action),
							criteria: _.cloneDeep(this.filters[idx].extraCrispy.criteria),
							id: this.filters[idx].original.id,
						},
					};
					let filterGCP = {
						action: _.cloneDeep(this.filters[idx].extraCrispy.action),
						criteria: _.cloneDeep(this.filters[idx].extraCrispy.criteria),
						id: '',
					};
					const gcpResponse = await this.setFilterCreate(filterGCP);
					const gcpFilterData = gcpResponse.data;
					filterGCP.action.forward = gcpFilterData.action.forward || '';
					filterGCP.action.addLabelIds = gcpFilterData.action.addLabelIds || [];
					filterGCP.action.removeLabelIds = gcpFilterData.action.removeLabelIds || [];
					filterGCP.criteria.from = gcpFilterData.criteria.from || '';
					filterGCP.criteria.excludeChats = gcpFilterData.criteria.excludeChats || false;
					filterGCP.criteria.hasAttachment = gcpFilterData.criteria.hasAttachment || false;
					filterGCP.criteria.negatedQuery = gcpFilterData.criteria.negatedQuery || '';
					filterGCP.criteria.query = gcpFilterData.criteria.query || '';
					filterGCP.criteria.size = gcpFilterData.criteria.size || 0;
					filterGCP.criteria.sizeComparison = gcpFilterData.criteria.sizeComparison || 'unspecified';
					filterGCP.criteria.subject = gcpFilterData.criteria.subject || '';
					filterGCP.criteria.to = gcpFilterData.criteria.to || '';
					filterGCP.id = gcpFilterData.id;
					let filterNew = {
						original: {
							action: _.cloneDeep(filterGCP.action) || [],
							criteria: _.cloneDeep(filterGCP.criteria),
							id: filterGCP.id,
						},
						extraCrispy: {
							editorButtonsEnabled: false,
							cancelUpdate: false,
							action: _.cloneDeep(filterGCP.action),
							criteria: _.cloneDeep(filterGCP.criteria),
							id: filterGCP.id,
						},
					};
					this.setFilterDelete(filterOriginal.original.id);
					this.setFilterReplace({ idx, filterNew });
					this.setFilterToggle(false);
					this.setFilterLoadingExisting(false);
					this.setDropdownHide(true);
					console.debug('Filter updated');
				}
			}
		},
		handleCancel(idx) {
			console.debug('handleCancel()');
			if (this.tool === 'label') {
				console.debug('label');
				this.setLabelCancel(idx);
			} else if (this.tool === 'filter') {
				console.debug('filter');
				this.setFilterCancel(idx);
				this.filterNew ? this.setFilterNew(false) : null;
				idx === this.filters.length - 1
					? this.setFilterNewInputValue('')
					: (this.setFilterExtraCrispyCriteriaFrom({
							criteria: { from: this.filters[idx].original.criteria.from },
							idx: idx,
					  }),
					  this.setDropdownHide(true));
			}
		},
		async handleDelete(idx) {
			console.debug('handleDelete()');
			if (this.tool === 'label') {
				console.debug('label');
				if (idx === this.labels.length - 1) {
					console.debug('labelNew');
					this.setLabelCancel(idx);
					this.setLabelNewInputReset(idx);
				} else if (idx < this.labels.length - 1) {
					console.debug('labelExisting');
					this.setLabelDelete(idx); // TODO: This needs to delete from GCP/Store separately
					this.setLabelRemove(idx);
					setTimeout(() => {
						this.setLabelLoadingExisting(false);
					}, 2000);
				}
			} else if (this.tool === 'filter') {
				console.debug('filter');
				this.setFilterLoadingExisting(idx);
				if (idx === this.filters.length - 1) {
					console.debug('filterNew');
					this.setDropdownHide(true);
					this.setFilterCancel(idx);
					this.setFilterNewInputValue('');
				} else if (idx < this.filters.length - 1) {
					console.debug('filterExisting');
					this.setFilterDelete(idx); // TODO: This needs to delete from GCP/Store separately
					// this.setFilterRemove(idx);
					setTimeout(() => {
						this.setFilterLoadingExisting(false);
					}, 2000);
				}
			}
		},
	},
};
</script>
<style lang="less">
/**
 * ToolItemEditor
 *
 * This component is used to display the editor buttons for the list-view and cell-view of label/filter items
 *
 * The editor buttons are displayed in a row and are centered horizontally for list view
 * and vertically for cell view
 *
 * // TODO:There is some cleanup in the css here as well as a lot of unneeded class names in the template
*/
/** LABEL */
.label-editor-button {
	width: 16px;
	height: 16px;
	margin: 1px;
}
.label-editor-button-icon {
	width: 16px;
	height: 16px;
	margin: 1px;
}
.label-editor-button-delete {
	margin-right: 1px;
}
.new-label-container > .label-editor {
	margin-right: 4px;
}
.label-editor {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	padding-bottom: 1px;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.label-editor > div:nth-child(1) {
	margin-right: 2px;
}
.label-editor > div:nth-child(2) {
	margin-right: 2px;
}
.label-editor-button-enabled {
	cursor: pointer;
}
// BUTTONS
// --CANCEL
.label-editor-button-enabled > .fa-circle-xmark {
	color: var(--PRIMARY_YELLOW);
}
.label-editor-button-enabled > .fa-circle-xmark:hover {
	color: var(--PRIMARY_GREEN);
}
.label-editor-button-disabled > .fa-circle-xmark {
	color: var(--PRIMARY_GRAY);
	cursor: not-allowed !important;
}
// --APPROVE
.label-editor-button-enabled > .fa-circle-check {
	color: var(--PRIMARY_GREEN);
}
.label-editor-button-enabled > .fa-circle-check:hover {
	color: var(--PRIMARY_YELLOW);
}
.label-editor-button-disabled > .fa-circle-check {
	color: var(--PRIMARY_GRAY) !important; /** Applies primary gray to the icon when the button is disabled */
	cursor: not-allowed !important; /** Prevents the cursor from changing to a pointer when hovering over the button */
}
/** FILTER */
.filter-editor-button {
	width: 16px;
	height: 16px;
	margin: 1px;
}
.filter-editor-button-icon {
	width: 16px;
	height: 16px;
	margin: 1px;
}
.filter-editor-button-delete {
	margin-right: 1px;
}
.new-filter-container > .filter-editor {
	margin-right: 4px;
}
.filter-editor {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	padding-bottom: 1px;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.filter-editor > div:nth-child(1) {
	margin-right: 2px;
}
.filter-editor > div:nth-child(2) {
	margin-right: 2px;
}
.filter-editor-button-enabled {
	cursor: pointer;
}
// BUTTONS
// --CANCEL
.filter-editor-button-enabled > .fa-circle-xmark {
	color: var(--PRIMARY_YELLOW);
}
.filter-editor-button-enabled > .fa-circle-xmark:hover {
	color: var(--PRIMARY_GREEN);
}
.filter-editor-button-disabled > .fa-circle-xmark {
	color: var(--PRIMARY_GRAY);
	cursor: not-allowed !important;
}
// --APPROVE
.filter-editor-button-enabled > .fa-circle-check {
	color: var(--PRIMARY_GREEN);
}
.filter-editor-button-enabled > .fa-circle-check:hover {
	color: var(--PRIMARY_YELLOW);
}
.filter-editor-button-disabled > .fa-circle-check {
	color: var(--PRIMARY_GRAY) !important; /** Applies primary gray to the icon when the button is disabled */
	cursor: not-allowed !important; /** Prevents the cursor from changing to a pointer when hovering over the button */
}
// --DELETE
.fa-trash-can {
	color: var(--PRIMARY_RED); /** Applies primary red, automagically applied through FontAwesome */
}
.fa-trash-can:hover {
	color: var(--PRIMARY_YELLOW); /** Applies primary red, automagically applied through FontAwesome */
}
</style>
