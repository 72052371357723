<template>
	<!-- Input -->
	<div v-bind="$attrs" class="typeahead-wrapper typeahead-input-wrapper">
		<!-- New Label Input -->
		<input
			v-if="override"
			v-model="inputValue"
			@click="$event.target.select(), this.setActiveTool(idx)"
			@input.stop="handleInput($event, idx)"
			:class="`${getTypeaheadInputClass}`"
			:id="`new-label-name-input-${idx}`"
			:placeholder="placeholder"
			:size="size"
			:minlength="minlength"
			:maxlength="maxlength"
			type="text" />
		<!-- Label Input -->
		<input
			v-if="!override"
			v-model="inputValue"
			@click="$event.target.select(), this.setActiveTool(toolType)"
			@input.stop="handleInput($event, idx)"
			:class="`${getTypeaheadInputClass}`"
			:id="`label-name-input-${idx}`"
			:placeholder="placeholder"
			:size="size"
			:minlength="minlength"
			:maxlength="maxlength"
			type="text" />
	</div>
	<!-- Typeahead Results -->
	<div v-if="typeaheadInputClicked && inputValue" class="typeahead-wrapper typeahead-suggestions">
		<div
			v-for="(suggestion, idx) in typeaheadSuggestions"
			@click.stop="handleSuggestionClick(suggestion, idx)"
			:key="idx"
			class="typeahead-suggestion">
			{{ suggestion }}
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import * as actions from '../store/actions/actions';
import typeaheadConf from '../store/maps/typeaheadConf';
export default {
	name: 'TypeaheadInput',
	inheritAttrs: false,
	props: {
		id: {
			type: String,
		},
		idx: {
			type: Number,
		},
		minlength: {
			type: Number,
		},
		maxlength: {
			type: Number,
		},
		override: {
			type: Boolean,
		},
		placeholder: {
			type: String,
		},
		size: {
			type: Number,
		},
		type: {
			type: String,
		},
		toolType: {
			type: String,
		},
		value: {
			type: String,
		},
	},
	data() {
		return {
			inputIdx: 0,
			inputValue: this.value,
			newInputValue: 'Give me a new name...',
			typeaheadInputClicked: false,
		};
	},
	computed: {
		...mapState(typeaheadConf.config),
		getTypeaheadInputClass() {
			return this.toolType === 'labels' ? 'label-name-input' : 'filter-id-input';
		},
		getTypeaheadInputClicked() {
			return this.typeaheadInputClicked;
		},
		getTypeaheadSuggestions() {
			return this.typeaheadSuggestions;
		},
		getDraggable() {
			/** Returns the toggle property */
			return this.draggable;
		},
	},
	mounted() {
		document.addEventListener('click', this.onClickOutside.bind(this));
	},
	beforeUnmount() {
		document.removeEventListener('click', this.onClickOutside);
	},
	watch: {
		inputValue(newVal, oldVal) {
			// Placeholder for future use
		},
	},
	methods: {
		...mapActions({
			setActiveFilter: actions.SET_ACTIVE_FILTER,
			setActiveLabel: actions.SET_ACTIVE_LABEL,
			setActiveTool: actions.SET_ACTIVE_TOOL,
			setEditorButtonsEnabled: actions.SET_EDITOR_BUTTONS_ENABLED,
			setLabelExtraCrispy: actions.SET_LABEL_EXTRA_CRISPY,
			setLabelIdArray: actions.SET_LABEL_ID_ARRAY,
			setLabelNameArray: actions.SET_LABEL_NAME_ARRAY,
			setLabelPathArray: actions.SET_LABEL_PATH_ARRAY,
			setTypeaheadSuggestions: actions.SET_TYPEAHEAD_SUGGESTIONS,
		}),
		checkInputValueAgainstActiveItem(event, idx) {
			// console.debug('checkInputValueAgainstActiveItem()');
			if (this.toolType === 'labels') {
				/**
				 * Checks the input value against the active label
				 * to determine if the editor buttons should be enabled
				 */
				if (event !== undefined) {
					this.setEditorButtonsEnabled({ tool: 'labels', idx: idx });
				}
			} else if (this.toolType === 'filters') {
				/** Currently unused */
			}
		},
		dragFocusFix() {
			// document.getElementById(`label-id-${this.arrayIDX}`).focus;
		},
		handleInput(event, idx) {
			event.stopPropagation();
			// console.debug('handleInput()');
			this.typeaheadInputClicked = true;
			const storeUpdate = { name: this.inputValue, idx: idx };
			this.setLabelExtraCrispy(storeUpdate);
			this.setTypeaheadSuggestionsHandler(this.labels[idx].extraCrispy.name, this.id, idx);
			this.setEditorButtonsEnabled({ tool: 'labels', idx: idx });
		},
		handleSuggestionClick(suggestion, idx) {
			event.stopPropagation();
			// console.debug('handleSuggestionClick()');
			const inputElement = document.getElementById(`label-name-input-${this.idx}`);
			this.inputValue = suggestion;
			this.setLabelExtraCrispy({ name: suggestion, idx: this.idx });
			this.setEditorButtonsEnabled({ tool: 'labels', idx: this.idx });
			this.typeaheadInputClicked = false;
		},
		onClickOutside(event) {
			if (this.typeaheadInputClicked && !this.$el.contains(event.target)) {
				console.debug('click outside');
				this.restoreInputValue(event, this.idx);
			}
		},
		restoreInputValue(event, idx) {
			console.debug('restoreInputValue()');
			let tool, originalValue, extraCrispyName;
			if (this.toolType === 'labels') {
				tool = this.toolType;
				originalValue = this.labels[idx].original.name;
				extraCrispyName = this.labels[idx].extraCrispy.name;
			} else {
				tool = 'filters';
				originalValue = this.filters.original[idx].name;
				extraCrispyName = this.filters.extraCrispy[idx].name;
			}
			// console.debug(`${tool}`);
			const clickOutside = this.typeaheadInputClicked && !this.$el.contains(event.target);
			// Check if the input is empty
			if (!this.inputValue) {
				// console.debug('Input has no value, restoring to original value');
				this.setLabelExtraCrispy({ name: originalValue, idx: idx });
				const restoreInputValue = clickOutside && !this.inputValue ? originalValue : this.inputValue;
				// console.debug('restoreInputValue: ', restoreInputValue);
				this.inputValue = restoreInputValue;
				// console.debug('this.inputValue: ', this.inputValue);
				// const restoreTool = clickOutside ? 'typeahead' : tool;
				this.setEditorButtonsEnabled({ tool: tool, idx: idx });
				// console.debug('Input value, restored to original value');
			} else {
				// console.debug('Input has value, checking against active item');
				// If the input value differs from the original, retain the input value
				if (this.inputValue !== extraCrispyName) {
					this.setLabelExtraCrispy({ name: this.inputValue, idx: idx });
				}
				this.checkInputValueAgainstActiveItem(event, idx);
			}
			this.typeaheadInputClicked = false;
		},
		setDragToggle() {
			/**
			 * Specific for the app,
			 * meaning this could be pulled out for
			 * component reuse in another project.
			 *
			 * This turns off draggable when the input
			 * is clicked, so that when the input is
			 * clicked out and the label container element
			 * is immediately mousedown upon, the drag
			 * can be reapplied and the side effect of
			 * element selection rather than drag can be
			 * prevented
			 */
			this.$parent.setDragToggle(); // TODO: This is a hacky way to get the parent method. Should be a better way.
		},
		setTypeaheadInputClicked() {
			this.typeaheadInputClicked = this.typeaheadInputClicked ? false : true;
		},
		setTypeAheadInputValue(id) {
			const element = document.getElementById(id);
			if (!element.value || element.value === element.placeholder) {
				element.value = element.placeholder;
			}
		},
		setTypeaheadSuggestionsHandler() {
			// console.debug('setTypeaheadSuggestionsHandler()');
			if ((this.typeaheadInputClicked && this.inputValue !== null) || this.inputValue !== '') {
				if (this.toolType === 'labels') {
					// TODO: Adjust this to use the location for the new typeaheadPreloadObject
					const filteredLabelPathArray = this.labelPathArray.filter((label) =>
						label.toLowerCase().startsWith(this.inputValue.toLowerCase()),
					);
					// TODO: Adjust this to use the location for the new typeaheadPreloadObject
					const filteredLabelNameArray = this.labelNameArray.filter((label) =>
						label.toLowerCase().startsWith(this.inputValue.toLowerCase()),
					);
					this.setTypeaheadSuggestions(_.merge(filteredLabelNameArray, filteredLabelPathArray));
				} else if (this.toolType === 'filters') {
					// TODO: Add logic for filters here
					// console.debug('filters');
				}
			}
		},
	},
};
</script>

<style lang="less">
.typeahead {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin-left: 4px;
	border-left: var(--PRIMARY_BORDER);
}
.typeahead-wrapper {
	flex: 1;
}
.typeahead-input-wrapper {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	height: 100%;
	border-radius: 0 4px 4px 0;
	z-index: 79;
	cursor: pointer;
}
.label-name-input,
.new-label-name-input {
	border: none;
	width: 100%;
	height: 100%;
	padding: 0;
	text-align: center;
}
.label-name-input:hover,
.label-name-input:focus,
.new-label-name-input:hover,
.new-label-name-input:focus {
	background-color: var(--PRIMARY_BLUE);
	color: var(--PRIMARY_WHITE);
	outline: none;
}
.typeahead-suggestion {
	flex: 1;
	width: 100%;
	padding: 2px;
	background-color: var(--PRIMARY_GRAY);
	border: 1px solid #ddd;
	z-index: 99;
	cursor: pointer;
}
.typeahead-suggestion:hover {
	background-color: var(--PRIMARY_BLUE);
	color: var(--PRIMARY_WHITE);
}
.typeahead-suggestions {
	position: relative;
	z-index: 99;
	background-color: var(--PRIMARY_WHITE);
	border: var(--PRIMARY_BORDER_SOLID_WHITE);
	max-height: 50px;
	overflow-y: auto;
	overflow-x: hidden;
}
.typeahead-suggestions {
	scrollbar-width: auto;
	scrollbar-color: var(--PRIMARY_GRAY) var(--PRIMARY_GREEN);
}
</style>
