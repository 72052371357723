import { mapState } from 'vuex';

export default {
	config: mapState([
		// APP
		'activeFilter', // Active filter data object taken from this.filters
		'activeLabel', // Active filter data object taken from this.labels
		'activeTool', // Active filter/label tool
		'dropdownHide', // Used to hide the dropdown menu
		'error', // Toggle error
		'errorMessage', // Initialized with empty string
		'filterApprove', // Used to track if the user is approving a filter
		'filterDelete', // Used to track if the user is deleting a filter
		'filterNew', // Used to track if the user is creating a new filter
		'filters', // User's filters retrieved from GCP after Oauth
		'labelApprove', // Used to track if the user is creating a new label
		'labelDelete', // Used to track if the user is deleting a label
		'labelNew', // Used to track if the user is creating a new label
		'labelUpdate', // Used to track if the user is creating a new label
		'labels', // User's labels retrieved from GCP after Oauth
		'toolView', // Used to track the view of the tool - list/cells
		'toolViewLabel', // Used to track the view of the label tool - list/cells
		'toolViewFilter', // Used to track the view of the filter tool - list/cells
	]),
};
