<template>
	<div class="toolbar-header">
		<h3 class="toolbar-title">{{ title ? title : '' }}</h3>
		<div class="toolbar-buttons-wrapper">
			<!-- <div
				@click.stop="setToolView()"
				:class="`toolbar-button ${toolView}-view-button`"
				:id="`${title}-view-${toolView}-button`">
				<a href="#">
					<font-awesome-icon
						:icon="`fa-solid fa-table-${toolView}`"
						:class="`toolbar-icon toolbar-${toolView}-icon`"
						size="2x" />
				</a>
			</div> -->
			<div
				@click="toolType === 'label' ? setLabelNew(true) : setFilterNew(true)"
				class="toolbar-button add-button">
				<a href="#">
					<font-awesome-icon icon="fa-solid fa-plus" class="toolbar-icon toolbar-plus-icon" size="2x" />
				</a>
			</div>
			<div :class="`toolbar-button stripe-button`">
				<a @click.stop="window.location.replace(this.endpointStripeCheckoutURL)" href="#">
					<font-awesome-icon
						icon="fa-solid fa-hand-holding-dollar"
						class="toolbar-icon toolbar-hand-holding-dollar-icon"
						size="2x" />
				</a>
			</div>
		</div>
		<slot><!-- //TODO: Label/Filter count will end up here --></slot>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as actions from '../store/actions/actions';
import toolBarConf from '../store/maps/toolBarConf';
export default {
	name: 'ToolBar',
	components: {},
	props: {
		toolType: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			title: `${this.toolType.charAt(0).toUpperCase().concat(this.toolType.slice(1))}s`,
			tool: this.toolType.toLowerCase().slice(0, this.toolType.length - 1),
		};
	},
	methods: {
		...mapState(toolBarConf.config),
		...mapActions({
			setFilterNew: actions.SET_FILTER_NEW,
			setLabelNew: actions.SET_LABEL_NEW,
		}),
	},
};
</script>

<style lang="less">
.toolbar-header {
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
	z-index: 1;
	min-height: 104px;
	max-height: 104px;
	width: 100%;
	background-color: var(--PRIMARY_WHITE);
	border-bottom: var(--PRIMARY_BORDER);
}
.toolbar-title {
	flex: 1;
	text-align: center;
}
.toolbar-buttons-wrapper {
	display: flex;
	flex: 1;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	margin-bottom: 10px;
	border: var(--PRIMARY_BORDER);
	border-radius: 8px;
}
.toolbar-button {
	box-sizing: content-box;
	flex: 1;
	margin: 4px;
	max-height: 32px;
	max-width: 32px;
	border: var(--PRIMARY_BORDER);
	border-radius: 8px;
	padding: 4px;
	cursor: pointer;
}
.toolbar-button * {
	cursor: pointer;
}
.toolbar-button:hover {
	background-color: var(--PRIMARY_GRAY);
	border-color: var(--PRIMARY_GRAY_DARK);
	cursor: pointer !important;
}
.toolbar-icon {
	cursor: pointer;
}
.toolbar-list-icon {
	color: var(--PRIMARY_RED);
}
.toolbar-list-icon {
	color: var(--PRIMARY_RED);
}
.toolbar-cells-icon {
	color: var(--PRIMARY_BLUE);
}
.toolbar-plus-icon {
	color: var(--PRIMARY_YELLOW);
}
.toolbar-hand-holding-dollar-icon {
	color: var(--PRIMARY_GREEN);
}
</style>
